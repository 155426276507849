program Registration;

uses
  Vcl.Forms,
  WEBLib.Forms,
  uRegistration in 'uRegistration.pas'{*.html},
  Wrapper.Person in 'Objects\Wrapper.Person.pas',
  WEBLib.JSON in 'Objects\WEBLib.JSON.pas',
  Wrapper.Lookups in '..\..\SERVER\SidemindsModule\objects\Wrapper.Lookups.pas',
  Intf.Cities in '..\..\SERVER\SidemindsModule\interfaces\Intf.Cities.pas',
  Intf.CompanyPositions in '..\..\SERVER\SidemindsModule\interfaces\Intf.CompanyPositions.pas',
  Intf.Countries in '..\..\SERVER\SidemindsModule\interfaces\Intf.Countries.pas',
  Intf.PostalCodes in '..\..\SERVER\SidemindsModule\interfaces\Intf.PostalCodes.pas',
  Intf.States in '..\..\SERVER\SidemindsModule\interfaces\Intf.States.pas',
  Intf.Wrapper.Lookups in '..\..\SERVER\SidemindsModule\interfaces\Intf.Wrapper.Lookups.pas',
  Intf.Ancestor in '..\..\common\Interfaces\Intf.Ancestor.pas',
  Custom.Enumerations in '..\..\common\Custom Types\Custom.Enumerations.pas',
  Intf.SeniorityRoles in '..\..\SERVER\SidemindsModule\interfaces\Intf.SeniorityRoles.pas',
  uCookies in 'Lib\uCookies.pas',
  Data_UserProfile in 'Data\Data_UserProfile.pas',
  uEmail in 'Lib\uEmail.pas';

{$R *.res}

begin
  Application.Initialize;
  Application.MainFormOnTaskbar := True;
  Application.CreateForm(TRegistrationFrm, RegistrationFrm);
  Application.Run;
end.
