/// -----------------------------------------------------------------------------
/// <summary>
/// Unit Name: dmStripe
/// Author:    CrisScanferla
/// Date:      06-Apr-2023
/// </summary>
///
/// <remarks>
/// Stripe API reference: https://stripe.com/docs/api?lang=java
/// https://stripe.com/docs/connect
/// https://stripe.com/docs/checkout/quickstart
/// Class atributes with sufixes to help
/// OUT: use it only for reading
/// all the classes have id_OUT. Stripe API returns this ID when inserting... and needs it when listing, deleting or updating.
/// Fsk -  API SK KEY needed for all services
///
/// CARDS NUMBERS
/// 4242 4242 4242 4242
/// 4000 0025 0000 3155        needs auth
/// 4000 0000 0000 9995        refused
///
/// Date:      06-May-2023. CrisScanferla
/// for STRIPE TAX information, see https://stripe.com/docs/tax/set-up
/// Added automatic calculation  of taxes on sales and oportunity for discount coupom
/// Added Class Tax rate object
/// Added Subscription class
/// added receipt, cancelation url, refunds url and cancelation reason on payment class
/// added tax behaviour on price class.
/// </remarks>
/// -----------------------------------------------------------------------------
unit uDMStripe;
interface

uses
  System.SysUtils,
  System.Classes,
  JS,
  Web,
  WEBLib.Graphics,
  WEBLib.Controls,
  WEBLib.Forms,
  WEBLib.Dialogs,
  Vcl.Controls,
  Vcl.StdCtrls,
  WEBLib.StdCtrls,
  WEBLib.REST,
  WEBLib.JSON;
type

//*** Tax Code ***
   TTaxCodeStatus =(tsTaxCodeSuccess,tsTaxCodeMissingSk,tsTaxCodeMissingID,tsTaxCodeNoResponse,
                    tsTaxCodeError,tsTaxCodeListSuccess);

   TStripeTaxCodeStatus = procedure (sender : Tobject; errortype : TTaxCodeStatus) of object;

   TTaxCode = class (tComponent)
   private
    { private declarations }
    Fsk: string;
    Fid:string;
    Fdescription: string;
    Fname:string;
    FStatus_OUT:string;
    FreturnMessage_OUT:string;
    FonStripeTaxCodeStatus: TStripeTaxCodeStatus;
    whReqTax: TWebHttpRequest;
   protected
    { protected declarations }
   public
    { public declarations }
    property sk: string read Fsk write Fsk;
    property id:string read Fid write Fid;
    property description:string read Fdescription write Fdescription;
    property name:string read Fname write Fname;
    property Status_OUT:string read FStatus_OUT write FStatus_OUT;
    property returnMessage_OUT:string read FreturnMessage_OUT write FreturnMessage_OUT;
    procedure GetStripeTaxCode(Sender: Tobject);
   published
    { published declarations }
    property onStripeTaxCodeStatus : TStripeTaxCodeStatus  read FonStripeTaxCodeStatus write FonStripeTaxCodeStatus;
   end;

   TTaxCodeList = array of TTaxCode;

   TTaxCodes = class (tComponent)
   private
    { private declarations }
    Fsk: string;
    FTaxCodes: TTaxCodeList;
    FStatus_OUT: string;
    FreturnMessage_OUT: string;
    FonStripeTaxCodeStatus: TStripeTaxCodeStatus;
    whReqTax: TWebHttpRequest;
   protected
    { protected declarations }
   public
    { public declarations }
    property sk: string read Fsk write Fsk;
    property TaxCodes: TTaxCodeList read FTaxCodes write FTaxCodes;
    property Status_OUT:string read FStatus_OUT write FStatus_OUT;
    property returnMessage_OUT:string read FreturnMessage_OUT write FreturnMessage_OUT;
    procedure GETTaxCodeList(Sender: Tobject);
    procedure PutTaxCode(value:TTaxCode);
   published
    { published declarations }
    property onStripeTaxCodeStatus: TStripeTaxCodeStatus  read FonStripeTaxCodeStatus write FonStripeTaxCodeStatus;
   end;
///***Tax Rate***

  TTaxRateStatus = (tsTaxSuccess, tsTaxNoResponse, tsTaxIncludeSucess, tsTaxGetSucess,
                   tsTaxMissingdisplay_name,tsTaxMissingSk,tsTaxMissinginclusive,tsTaxMissingPercentage,
                   tsTaxError,tsTaxUpdateSuccess,tsTaxMissingID,tsTaxGETSuccess);
  TStripeTaxStatus = procedure (sender : Tobject; errortype : tTaxRateStatus) of object;

  TTaxRate = class (tComponent)
  private
    { private declarations }
    Fsk: string;
    Fid_OUT: string;
    Factive: integer; // 1 is true - active
    Fcountry: string;
    Fdisplay_name: string;
    Finclusive: integer; // 1 is inclusive at product price, 2 is exclusive from product price
    Fjurisdiction:string;
    Fpercentage: integer;
    Fstate: string;
    FTax_type: string;
    FStatus_OUT: string;
    FreturnMessage_OUT: string;
    FonStripeTaxStatus: TStripeTaxStatus;
    whReqTax: TWebHttpRequest;
  protected
    { protected declarations }
  public
    { public declarations }
    property sk: string read Fsk write Fsk;
    property id_OUT: string read Fid_OUT write Fid_OUT;
    property active: integer read Factive write Factive;
    property country: string read Fcountry write Fcountry;
    property display_name: string read Fdisplay_name write Fdisplay_name;
    property inclusive: integer read Finclusive write Finclusive;
    property jurisdiction: string read Fjurisdiction write Fjurisdiction;
    property percentage: integer read Fpercentage write Fpercentage;
    property state: string read Fstate write Fstate;
    property Tax_type: string read FTax_type write FTax_type;
    property Status_OUT:string read FStatus_OUT write FStatus_OUT;
    property returnMessage_OUT:string read FreturnMessage_OUT write FreturnMessage_OUT;
    procedure InsertStripeTaxRate(sender: Tobject);
    procedure UpdateStripeTaxRate(sender: Tobject);
    procedure GetStripeTaxRate(sender: Tobject);
  published
    { published declarations }
    property onStripeTaxStatus : TStripeTaxStatus  read FonStripeTaxStatus write FonStripeTaxStatus;
  end;

// ***Customers ***

  TCustomerStatus = (tsCustomerSuccess,tsCustomerMissingSk,tsCustomerNoResponse, tsCustomerStripeError,tsCustomerMissingID,tsGETCustomerSuccess,
                     tsDELETECustomerSuccess,tsUPDATECustomerSuccess);
  TStripeCustomerStatus = procedure (sender : Tobject; errortype : TCustomerStatus) of object;

  TCustomer = class (tComponent)
  private
    { private declarations }
    Fsk: string;
    Fid_OUT: string;
    Fname: string;
    Fphone: string;
    Femail: string;
    FStatus_OUT: string;
    FreturnMessage_OUT: string;
    whReqCustomer: TWebHttpRequest;
    FonStripeCustomerStatus: TStripeCustomerStatus;
  protected
    { protected declarations }
  public
    { public declarations }
    property sk: string read Fsk write Fsk;
    property id_OUT: string read Fid_OUT write Fid_OUT;
    property name: string read Fname write Fname;
    property phone: string read Fphone write Fphone;
    property email: string read Femail write Femail;
    property returnMessage_OUT: string read FreturnMessage_OUT write FreturnMessage_OUT;
    property Status_OUT: string read FStatus_OUT write FStatus_OUT;
    procedure InsertStripeCustomer(sender: Tobject);
    procedure GETCustomer(sender: Tobject);
    procedure DELETECustomer(sender: Tobject);
    procedure UPDATECustomer(sender: Tobject);
  published
    { published declarations }
      property onStripeCustomerStatus : TStripeCustomerStatus  read FonStripeCustomerStatus write FonStripeCustomerStatus;
  end;

// ***Checkout Items***


  TTaxRates = array of string;

  Tline_items = class  (tComponent)
  private
    { private declarations }
    Fid_price: string;  //price product ID at Stripe
    Fquantity: integer;
    FTaxRates: TTaxRates;
  protected
    { protected declarations }
  public
    { public declarations }
    property id_price: string read Fid_price write Fid_price;
    property quantity: integer read Fquantity write Fquantity;
    property TaxRAtes: TTaxRates read FTaxRates write FTaxRates;
    procedure SETTaxRates(const Value:string);
    function GETTaxRates: tTaxRates;
  published
    { published declarations }
  end;

  Tarray_line_items = array of Tline_items;

// ***Checkout***

  TCheckoutStatus = (tsCheckoutSuccess, tsCheckoutMissingID,tsCheckoutMissingSk,tsCheckoutNoResponse, tsCheckoutStripeError,
                     tsCheckoutMissingItemPrice, tsCheckoutMissingItemQuantity,tsCheckoutMissingMode,
                     tsCheCkoutMissingSuccess_URL,tsCheckoutMissingItems);
  TStripeCheckoutStatus = procedure (Sender: Tobject; errortype : TCheckoutStatus) of object;

  Tcheckout = class  (tComponent)
  private
    { private declarations }
    Fsk: string;
    Fid_OUT: string;
    Furl_OUT:string;
    Fline_items: Tarray_line_items;
    Fmode: string; // payment, setup, subscription. When subscription, has to put a product/price that has recurring and interval
    Fcurrency: string;
    Fcustomer: string; // id of customer at stripe API
    FStatus_OUT: string;
    FreturnMessage_OUT: string;
    Fsuccess_url:string;
    Fpayment_intent:string;
    Fpayment_status:string;
    Fallow_promotion_codes: integer; //use '1' to use promotion codes
    Fautomatic_tax : integer;  //use '1' to automatic tax calculation. for automatic tax, customer has to give address and tax rates for this location have to be configured.
    Fbilling_address_collection:string;   //for billing address collection auto and required
    Ftrial_period_days : integer; //number of days for trial
    Ftrial_end_behavior:string;
    Fsubscription:string;
    FAmount_total:integer;
    Famount_discount: integer;
    Famount_shipping: integer;
    Famount_tax: integer;
    whReqCheckout: TWebHttpRequest;
    FonStripeCheckoutStatus: TStripeCheckoutStatus;
  protected
    { protected declarations }
  public
    { public declarations }
    property sk: string read Fsk write Fsk;
    property id_OUT: string read Fid_OUT write Fid_OUT;
    property url_OUT: string read Furl_OUT write Furl_OUT;
    property line_items:Tarray_line_items read Fline_items write Fline_items;
    property mode: string read Fmode write Fmode;
    property currency: string read Fcurrency write Fcurrency;
    property customer: string read Fcustomer write Fcustomer;
    property returnMessage_OUT: string read FreturnMessage_OUT write FreturnMessage_OUT;
    property Status_OUT: string read FStatus_OUT write FStatus_OUT;
    property success_url: string read Fsuccess_url write Fsuccess_url;
    property payment_intent: string read Fpayment_intent write Fpayment_intent;
    property payment_Status: string read Fpayment_status write Fpayment_status;//returns the payment when subscriptions
    property allow_promotion_codes: integer read Fallow_promotion_codes write Fallow_promotion_codes;
    property automatic_tax: integer read Fautomatic_tax write Fautomatic_tax;
    property trial_period_days: integer read Ftrial_period_days write Ftrial_period_days default 0;
    property trial_end_behavior:string read Ftrial_end_behavior write Ftrial_end_behavior;    //cancel pause create_invoice
    property subscription:string read Fsubscription write Fsubscription;
    property amount_total:integer read Famount_total write Famount_total;
    property amount_discount:integer read Famount_discount write Famount_discount;
    property amount_shipping:integer read Famount_shipping write Famount_shipping;
    property amount_tax:integer read Famount_tax write Famount_tax;
    property billing_address_collection:string read Fbilling_address_collection write Fbilling_address_collection;
    procedure SETline_items (const Value: Tline_items);
    function GETline_items: Tarray_line_items;
    procedure InsertStripeCheckout(sender : Tobject);
    procedure GETStripeCheckout(sender : Tobject);
  published
    { published declarations }
     property onStripeCheckoutStatus: TStripeCheckoutStatus read FonStripeCheckoutStatus write FonStripeCheckoutStatus;
  end;

// ***Payments ***

  TPaymentStatus = (tsPaymentSuccess,tsPaymentMissingSk,tsPaymentNoResponse, tsPaymentStripeError);
  TStripePaymentStatus = procedure (sender : Tobject; errortype : TPaymentStatus) of object;

  TPayment = class (tComponent)
  private
    { private declarations }
    Fclient_secret:string;
    Fsk: string;
    Fcurrency: string;
    Fcustomer: string;
    Famount: integer;
    Fstatus: string;
    whGetPayment: TWebHttpRequest;
    FStatus_OUT:string;
    FreturnMessage_OUT:string;
    FonStripePaymentStatus: TStripePaymentStatus;
    FreceiptURL:string;
    FrefundsURL:string;
    FcancelURL:string;
    Fcancelation_Reason: string;
  public
    { public declarations }
    property client_secret: string read Fclient_secret write Fclient_secret;
    property sk: string read Fsk write Fsk;
    property currency: string read Fcurrency write Fcurrency;
    property customer: string read Fcustomer write Fcustomer;
    property amount: integer read Famount write Famount;
    property status: string read Fstatus write Fstatus;
    property Status_OUT: string read FStatus_OUT write FStatus_OUT;
    property returnMessage_OUT: string read FreturnMessage_OUT write FreturnMessage_OUT;
    property receiptURL:string read FreceiptURL write FreceiptURL;
    property refundsURL:string read FrefundsURL write FrefundsURL;
    property cancelURL:string read FcancelURL write FcancelURL;
    property cancelation_Reason:string read Fcancelation_Reason write Fcancelation_Reason;
    procedure GETPaymentDetails(sender : Tobject);
  published
    { published declarations }
    property onStripePaymentStatus: TStripePaymentStatus read FonStripePaymentStatus write FonStripePaymentStatus;
  end;

// ***Subscriptions ***
  TsubscriptionStatus = (tssubscriptionSuccess,tssubscriptionMissingSk,tssubscriptionNoResponse, tssubscriptionStripeError);
  TStripesubscriptionStatus = procedure (sender : Tobject; errortype : TsubscriptionStatus) of object;

  Tsubscription = class (tComponent)
  private
    { private declarations }
    Fid_OUT: string;
    Fsk: string;
    Fcurrency: string;
    Fcustomer: string;
    Famount: integer;
    Fstatus: string;
    FStatus_OUT:string;
    FreturnMessage_OUT:string;
    Fcancellation_comment: string;
    Fcancellation_feedback: string;
    Fcancellation_reason: string;
    Fcancel_at: TDateTime;
    Fcanceled_at: TDateTime;
    FonStripesubscriptionStatus: TStripesubscriptionStatus;
    whGetsubscription: TWebHttpRequest;
  public
    { public declarations }
    property id_OUT: string read Fid_OUT write Fid_OUT;
    property sk: string read Fsk write Fsk;
    property currency: string read Fcurrency write Fcurrency;
    property customer: string read Fcustomer write Fcustomer;
    property amount: integer read Famount write Famount;
    property status: string read Fstatus write Fstatus;
    property Status_OUT: string read FStatus_OUT write FStatus_OUT;
    property returnMessage_OUT: string read FreturnMessage_OUT write FreturnMessage_OUT;
    property cancellation_comment:string read Fcancellation_comment write Fcancellation_comment;
    property cancellation_feedback:string read Fcancellation_feedback write Fcancellation_feedback;
    property cancellation_Reason:string read Fcancellation_Reason write Fcancellation_Reason;
    property cancel_at: TDateTime read Fcancel_at write Fcancel_at;
    property canceled_at: TDateTime read Fcanceled_at write Fcanceled_at;
    procedure GETsubscriptionDetails(sender : Tobject);
  published
    { published declarations }
    property onStripesubscriptionStatus: TStripesubscriptionStatus read FonStripesubscriptionStatus write FonStripesubscriptionStatus;
  end;

  // ***PRODUCTS AND PRICES ***

TPriceStatus = (tsPriceSuccess,tsPriceMissingSk,tsPriceNoResponse, tsPriceStripeError,tsPriceMissingCurrency,tsPriceMissingProduct,
                tsPriceMissingUnit_amount,tsPriceMissingRecurringInterval,tsPriceMissingRecurringInterval_count, tsPriceMissingID,
                tsPriceUPDATESuccess, tsPriceGETSuccess,tsPriceDELETESuccess);
TStripePriceStatus = procedure (sender : Tobject; errortype : TPriceStatus) of object;

TPrice = class (tComponent)
  private
    { private declarations }
    Fid_OUT: string;
    Fcurrency:string;
    Fproduct:string;
    Funit_amount: integer;
    Fnickname:string;
    Frecurring: boolean;
    FrecurringInterval: string; //day, week, month or year
    FrecurringInterval_count: integer; // maximun 1 year, 12 months or 52 weeks
    FonStripePriceStatus: TStripePriceStatus;
    Fsk:string;
    Ftax_behavior : integer;   // 1 included at price 0 excluded of price  ( example price 10 tax 0.10  included - customer pays 10. excluded - customer pays 10.10
    whPrices: TWebHttpRequest;
    FStatus_OUT:string;
    FreturnMessage_OUT:string;
  public
    { public declarations }
    property id_OUT: string read Fid_OUT write Fid_OUT;
    property currency:string read Fcurrency write Fcurrency;
    property product:string read Fproduct write Fproduct;
    property unit_amount:integer read Funit_amount write Funit_amount;
    property nickname:string read Fnickname write Fnickname;
    property recurring:boolean read Frecurring write Frecurring;
    property recurringInterval: string read FrecurringInterval write FrecurringInterval;
    property recurringInterval_count:integer read FrecurringInterval_count write FrecurringInterval_count;
    property Status_OUT: string read FStatus_OUT write FStatus_OUT;
    property returnMessage_OUT: string read FreturnMessage_OUT write FreturnMessage_OUT;
    property sk: string read Fsk write Fsk;
    property tax_behavior:integer read Ftax_behavior write Ftax_behavior;
    procedure InsertStripePrice(sender : Tobject);
    procedure UpdateStripePrice(sender : Tobject);
    procedure GETStripePrice(sender : Tobject);
    procedure DELETEStripePrice(sender : Tobject);
  published
    { published declarations }
    property onStripePriceStatus: TStripePriceStatus read FonStripePriceStatus write FonStripePriceStatus;
end;

TProductStatus = (tsProductSuccess,tsProductMissingSk,tsProductNoResponse, tsProducStripeError,tsProductMissingName,tsProductMissingID,
                  tsProductUPDATESuccess,tsProductGETSuccess, tsProductDELETESuccess,tsProductListPricesSuccess);
TStripeProductStatus = procedure (sender : Tobject; errortype : TProductStatus) of object;
Tprices = array of TPrice;

TProduct = class (tcomponent)
  private
    { private declarations }
    Fid_OUT:string;
    Fname:string;
    Fdefault_price:string;
    Fdescription:string;
    //images
    Furl:string;
    Fsk: string;
    whProducts: TWebHttpRequest;
    FStatus_OUT:string;
    FreturnMessage_OUT:string;
    FPrices: TPrices;
    Ftax_code: string;
    FonStripeProductStatus: TStripeProductStatus;
  public
    { public declarations }
    property name:string read Fname write Fname;
    property description: string read Fdescription write Fdescription;
    property url:string read Furl write Furl;
    property sk: string read Fsk write Fsk;
    property id_OUT: string read Fid_OUT write Fid_OUT;
    property default_price: string read Fdefault_price write Fdefault_price;
    property Status_OUT: string read FStatus_OUT write FStatus_OUT;
    property returnMessage_OUT: string read FreturnMessage_OUT write FreturnMessage_OUT;
    property prices: TPrices read FPrices write FPrices;
    property tax_code:string read Ftax_code write Ftax_code; // tax code from TTaxCode ID
    procedure PutPrice(value:TPrice);
    procedure InsertStripeProduct(sender : Tobject);
    procedure UPDATEStripeProduct(sender : Tobject);
    procedure GETStripeProduct(sender : Tobject);
    procedure DELETEStripeProduct(sender: TObject);
    procedure GETStripePriceList(sender : Tobject);
  published
    { published declarations }
    property onStripeProductStatus: TStripeProductStatus read FonStripeProductStatus write FonStripeProductStatus;
end;

  TdmStripe = class(TWebForm)
  private
    { Private declarations }
  public
    { Public declarations }
  end;

var
  dmStripe: TdmStripe;

implementation

{$R *.dfm}

/// -----------------------------------------------------------------------------
/// <summary>
/// Objects for Tax Codes
/// Author: CrisScanferla
/// Date: 06-May-2023
/// </summary>
/// ----------------------------------------------------------------------------
/// <remarks>
/// Object Description
///
/// TTaxCodeStatus =(tsTaxCodeSuccess,tsTaxCodeMissingSk,tsTaxCodeMissingID,tsTaxCodeNoResponse,
///                 tsTaxCodeError,tsTaxCodeListSuccess)
/// TStripeTaxCodeStatus = procedure (sender : Tobject; errortype : TTaxCodeStatus) of object;
///
///  CLASS TTaxCode
///
///properties:
/// sk : string - sk from client at stripe API. There is two sk options for each client. Test SK and Live SK. Pass the correct one to access each ambient type
/// id : string - if from tax code at stripe API
/// description : string. - Description of tax code at stripe API
/// name: string. - Name of tax code at Stripe API
/// Status_out: OK or ERROR to easy application control
/// ReturnMessage_OUT: string - complete return from Stripe
/// onStripeTaxCodeStatus: TStripeTaxCodeStatus; - use it for write your app behaviour with each one of the TTaxCodeStatus returned when using the class TTaxCode
/// </remarks>
///------------------------------------------------------------------------------


/// -----------------------------------------------------------------------------
/// <summary>
/// Procedure: TTaxCodes.GETTaxCodeList
/// Author: CrisScanferla
/// Date: 06-May-2023
/// Result: List of all tax Codes at Stripe
/// </summary>
/// ----------------------------------------------------------------------------
/// <remarks>
///     Ex call :  OBJTAXCODES.GETTaxCodeList(Sender);
/// </remarks>
/// <param name="sender: Tobject">
/// </param>
/// <param name="OBJTAXCODES.onStripeTaxCodeStatus">
/// Your app behaviour procedure(TStripeTaxCodeStatus) to deal with each one of the  TTaxCodeStatus.
/// </param>
/// <param name=OBJTAXCODES.sk >
/// string - sk from client at stripe API. There is two sk options for each client. Test SK and Live SK. Pass the correct one to access each ambient type
/// </param>
///------------------------------------------------------------------------------

procedure TTaxCodes.GETTaxCodeList(sender: Tobject);
var
  auxResponse: String;
  jsonObj,oData,oRecurring: TJSONObject;
  error,oResponse: TJSONObject;
begin
  whReqTax := TWebHttpRequest.Create(self);
  whReqTax.Command := httpGET;
  whReqTax.Headers.Clear;
  whReqTax.Headers.Add('Cache-Control=no-cache, no-store, must-revalidate');
  whReqTax.Headers.Add('Content-Type=application/x-www-form-urlencoded');
  if trim(Fsk) = '' then
  begin
      onStripeTaxCodeStatus( sender,tsTaxCodeMissingSk);
      raise Exception.Create('');
  end;
  whReqTax.Headers.Add('Authorization=Bearer ' + Fsk);
  whReqTax.URL := 'https://api.stripe.com/v1/tax_codes';
  try
    whReqTax.execute(
       procedure(auxResponse:string; ARequest: TJSXMLHttpRequest)
       var oList: TJSONArray;
           oItemList :TJSONValue;
           oTaxCode: TTaxCode;
       begin
         if auxResponse <> '' then
          begin
            oResponse := TJSONObject.ParseJSONValue(auxResponse) as TJSONObject;
            oList:=tJSONArray(oResponse.GetValue('data'));
            if not(oList =nil)  then
            begin
                oTaxCode:=TTaxCode.Create(self);
                for oItemList in oList do
                begin
                   oData:=TJSONObject(oItemList);
                   jsonObj := TJSONObject.ParseJSONValue(oData.ToString) as TJSONObject;
                   oTaxCode.id := jsonObj.Get('id').JsonValue.value;
                   oTaxCode.name := jsonObj.Get('name').JsonValue.value;
                   oTaxCode.description := jsonObj.Get('description').JsonValue.value;
                   PutTaxCode(oTaxCode);
                end;
                FStatus_OUT := 'OK';
                FreturnMessage_OUT := auxResponse;
                if assigned(onStripeTaxCodeStatus) then
                  onStripeTaxCodeStatus(sender, tsTaxCodeListSuccess);
            end
            else begin
                FStatus_OUT := 'OK';
                FreturnMessage_OUT := 'No tax Codes Found';
                if assigned(onStripeTaxCodeStatus) then
                  onStripeTaxCodeStatus(sender, tsTaxCodeListSuccess);
            end;
          end
          else
          begin
            FStatus_OUT := 'ERROR';
            FreturnMessage_OUT := 'Connection Error';
            FTaxCodes := [];
            if assigned(onStripeTaxCodeStatus) then
              onStripeTaxCodeStatus(sender, tsTaxCodeNoResponse);
          end;
       end
    )
  except
    FStatus_OUT := 'ERROR';
    jsonObj := TJSONObject.ParseJSONValue(auxResponse) as TJSONObject;
    error := TJSONObject(jsonObj.Get('error').JsonValue);
    FreturnMessage_OUT := error.Get('message').JsonValue.value;
    FTaxCodes := [];
    if assigned(onStripeTaxCodeStatus) then
        onStripeTaxCodeStatus(sender, tsTaxCodeError);
  end;
end;

/// -----------------------------------------------------------------------------
/// <summary>
/// Procedure: TTaxCodes.PutTaxCode(value:TTaxCode)
/// Author: CrisScanferla
/// Date: 06-May-2023
/// Result: List of all tax Codes at Stripe
/// </summary>
/// ----------------------------------------------------------------------------
/// <remarks>
///     Internal method to add a taxcode value to a   TTaxCode<List>
/// </remarks>


procedure TTaxCodes.PutTaxCode(value:TTaxCode);
var i: integer;
begin
    i := length(FTaxCodes);
    SetLength(FTaxCodes,i+1);
    FTaxCodes[i]:= TTaxCode.Create(self);
    FTaxCodes[i].Fid:=value.id;
    FTaxCodes[i].Fdescription:=value.description;
    FTaxCodes[i].Fname:=value.name;
end;

/// -----------------------------------------------------------------------------
/// <summary>
/// Procedure: TTaxCode.GetStripeTaxCode(Sender : Tobject)
/// Author: CrisScanferla
/// Date: 06-May-2023
/// Result: Gets 1 tax Code from Stripe
/// </summary>
/// ----------------------------------------------------------------------------
/// <remarks>
///     Ex call :  OBJTAXCODE.GetStripeTaxCode(Sender);
/// </remarks>
/// <param name="sender: Tobject">
/// </param>
/// <param name="OBJTAXCODE.onStripeTaxCodeStatus">
/// Your app behaviour procedure(TStripeTaxCodeStatus) to deal with each one of the  TTaxCodeStatus.
/// </param>
/// <param name=OBJTAXCODE.sk >
/// string - sk from client at stripe API. There is two sk options for each client. Test SK and Live SK. Pass the correct one to access each ambient type
/// </param>
/// <param name=OBJTAXCODE.id >
/// string - id from tax code you want to detail from stripe.
/// </param>
///------------------------------------------------------------------------------
procedure TTaxCode.GetStripeTaxCode(Sender : Tobject);
var
  auxResponse: String;
  //req: TJSXMLHttpRequest;
  jsonObj: TJSONObject;
  error: TJSONObject;
begin
  whReqTax := TWebHttpRequest.Create(self);
  whReqTax.Command := httpGET;
  whReqTax.Headers.Clear;
  whReqTax.Headers.Add('Cache-Control=no-cache, no-store, must-revalidate');
  whReqTax.Headers.Add('Content-Type=application/x-www-form-urlencoded');
  if trim(Fsk) = '' then
   begin
      onStripeTaxCodeStatus(Sender,tsTaxCodeMissingSk);
      raise Exception.Create('');
   end
  else
    whReqTax.Headers.Add('Authorization=Bearer ' + Fsk);
  if FID<>'' then
           whReqTax.URL := 'https://api.stripe.com/v1/tax_codes/'+FID
  else
  begin
    onStripeTaxCodeStatus(Sender,tsTaxCodeMissingID);
    raise Exception.Create('');
  end;

  try
    whReqTax.execute(procedure(auxResponse:string; ARequest: TJSXMLHttpRequest)
    begin
        if auxResponse <> '' then
        begin
          jsonObj := TJSONObject.ParseJSONValue(auxResponse) as TJSONObject;
          Fdescription := jsonObj.Get('description').JsonValue.value;
          Fname := jsonObj.Get('name').JsonValue.value;
          if assigned(onStripeTaxCodeStatus) then
            onStripeTaxCodeStatus(sender, tsTaxCodeSuccess);
        end
        else
        begin
          FStatus_OUT := 'ERROR';
          FreturnMessage_OUT := 'Connection Error';
          Fid := '0';
          if assigned(onStripeTaxCodeStatus) then
            onStripeTaxCodeStatus(sender, tsTaxCodeNoResponse);
        end;
    end)
  except
    FStatus_OUT := 'ERROR';
    jsonObj := TJSONObject.ParseJSONValue(auxResponse) as TJSONObject;
    error := TJSONObject(jsonObj.Get('error').JsonValue);
    FreturnMessage_OUT := error.Get('message').JsonValue.value;
    Fid := '0';
    if assigned(onStripeTaxCodeStatus) then
        onStripeTaxCodeStatus(sender, tsTaxCodeError);
  end;
end;

/// -----------------------------------------------------------------------------
/// <summary>
/// Objects for Prices
/// Author: CrisScanferla
/// Date: 06-Apr-2023
/// </summary>
/// ----------------------------------------------------------------------------
/// <remarks>
/// Object Description
///
/// TPriceStatus = (tsPriceSuccess,tsPriceMissingSk,tsPriceNoResponse, tsPriceStripeError,tsPriceMissingCurrency,tsPriceMissingProduct,
///                tsPriceMissingUnit_amount,tsPriceMissingRecurringInterval,tsPriceMissingRecurringInterval_count, tsPriceMissingID,
///                tsPriceUPDATESuccess, tsPriceGETSuccess,tsPriceDELETESuccess);
/// TStripePriceStatus = procedure (sender : Tobject; errortype : TPriceStatus) of object;
///
///  CLASS TPrice
///
///properties:
/// sk : string - sk from client at stripe API. There is two sk options for each client. Test SK and Live SK. Pass the correct one to access each ambient type
/// id_OUT : string - if from price at stripe API
/// currency: string - Three-letter ISO currency code, in lowercase. Must be a supported currency.
/// product: string - product ID on Stripe API
/// unit_amount: integer. - The unit amount in cents to be charged. EX: 100 is 1.00
/// nickname: string. Nickname for price identification. Not required.
/// recurring: boolean. If True, price is recurring.. so its a subscription. If it is false, it is for a 1 time payment.
/// recurringInterval: string. Use it if recurring=true. Can be day, week, month or year
/// recurringInterval_count: integer. Use it if recurring=true. The number of intervals. (ex... if 4 and recurringInterval = month, it will be for 4 months)
/// tax_behavior: use it to calculate taxes on stripe APP. Not required.  1 included at price 0 excluded of price  ( example price 10 tax 0.10  included - customer pays 10. excluded - customer pays 10.10)
/// ReturnMessage_OUT: string - complete return from Stripe
/// FonStripePriceStatus: : TPriceStatus; - use it for write your app behaviour with each one of the TPriceStatus returned when using the class TPrice
/// </remarks>
///------------------------------------------------------------------------------

/// -----------------------------------------------------------------------------
/// <summary>
/// Procedure: TPrice.DELETEStripePrice(sender : Tobject)
/// Author: CrisScanferla
/// Date: 06-Apr-2023
/// Result: Gets 1 tax Code from Stripe
/// </summary>
/// ----------------------------------------------------------------------------
/// <remarks>
///     Ex call :  OBJPRICE.DELETEStripePrice(sender);
/// </remarks>
/// <param name="sender: Tobject">
/// </param>
/// <param name="OBJPRICE.onStripePriceStatus">
/// Your app behaviour procedure(TStripePriceStatus) to deal with each one of the  TPriceStatus.
/// </param>
/// <param name=OBJPRICE.sk >
/// string - sk from client at stripe API. There is two sk options for each client. Test SK and Live SK. Pass the correct one to access each ambient type
/// </param>
/// <param name=OBJPRICE.id_OUT >
/// string - id from price you want to "delete" from stripe. We cannot delete prices, so this procedure, in fact, inactivate the price.
/// </param>
///------------------------------------------------------------------------------
procedure TPrice.DELETEStripePrice(sender : Tobject);   //sets price to inactive
var
  auxResponse: String;
  jsonObj: TJSONObject;
  error: TJSONObject;
begin

  whPrices := TWebHttpRequest.Create(self);
  whPrices.Command := httpPOST;
  whPrices.Headers.Clear;
  whPrices.Headers.Add('Cache-Control=no-cache, no-store, must-revalidate');
  whPrices.Headers.Add('Content-Type=application/x-www-form-urlencoded');
  if trim(Fsk) = '' then
   begin
      onStripePriceStatus( sender,tsPriceMissingSk);
      raise Exception.Create('');
    end
  else
    whPrices.Headers.Add('Authorization=Bearer ' + Fsk);
  if trim(Fid_OUT) = '' then
   begin
      onStripePriceStatus( sender,tsPriceMissingID);
      raise Exception.Create('');
    end
  else
  whPrices.URL := 'https://api.stripe.com/v1/prices/'+Fid_OUT;
  whPrices.PostData:='active=false';

  try

    whPrices.execute(procedure(auxResponse:string; ARequest: TJSXMLHttpRequest)
    begin
          if auxResponse <> '' then
          begin
            jsonObj := TJSONObject.ParseJSONValue(auxResponse) as TJSONObject;
            Fid_OUT := jsonObj.Get('id').JsonValue.value;
            FStatus_OUT := 'OK';
            FreturnMessage_OUT := auxResponse;
            if assigned(onStripePriceStatus) then
              onStripePriceStatus(sender, tsPriceDELETESuccess);
          end
          else
          begin
            FStatus_OUT := 'ERROR';
            FreturnMessage_OUT := 'Connection Error';
            Fid_OUT := '0';
            if assigned(onStripePriceStatus) then
              onStripePriceStatus(sender, tsPriceNoResponse);
          end;
    end)
  except
    FStatus_OUT := 'ERROR';
    jsonObj := TJSONObject.ParseJSONValue(auxResponse) as TJSONObject;
    error := TJSONObject(jsonObj.Get('error').JsonValue);
    FreturnMessage_OUT := error.Get('message').JsonValue.value;
    Fid_OUT := '0';
    if assigned(onStripePriceStatus) then
        onStripePriceStatus(sender, tsPriceStripeError);
  end;
end;

/// -----------------------------------------------------------------------------
/// <summary>
/// Procedure: TPrice.GETStripePrice(sender : Tobject)
/// Author: CrisScanferla
/// Date: 06-Apr-2023
/// Result: Gets 1 tax Code from Stripe
/// </summary>
/// ----------------------------------------------------------------------------
/// <remarks>
///     Ex call :  OBJPRICE.GETStripePrice(sender : Tobject);
/// </remarks>
/// <param name="sender: Tobject">
/// </param>
/// <param name="OBJPRICE.onStripePriceStatus">
/// Your app behaviour procedure(TStripePriceStatus) to deal with each one of the  TPriceStatus.
/// </param>
/// <param name=OBJPRICE.sk >
/// string - sk from client at stripe API. There is two sk options for each client. Test SK and Live SK. Pass the correct one to access each ambient type
/// </param>
/// <param name=OBJPRICE.id_OUT >
/// string - id from price you want to see all details from stripe.
/// </param>
///------------------------------------------------------------------------------
procedure TPrice.GETStripePrice(sender : Tobject);
var
  auxResponse: String;
  jsonObj,oRecurring: TJSONObject;
  error: TJSONObject;
begin

  whPrices := TWebHttpRequest.Create(self);
  whPrices.Command := httpGET;
  whPrices.Headers.Clear;
  whPrices.Headers.Add('Cache-Control=no-cache, no-store, must-revalidate');
  whPrices.Headers.Add('Content-Type=application/x-www-form-urlencoded');
  if trim(Fsk) = '' then
   begin
      onStripePriceStatus( sender,tsPriceMissingSk);
      raise Exception.Create('');
    end
  else
    whPrices.Headers.Add('Authorization=Bearer ' + Fsk);
  if trim(Fid_OUT) = '' then
   begin
      onStripePriceStatus( sender,tsPriceMissingID);
      raise Exception.Create('');
    end
  else
  whPrices.URL := 'https://api.stripe.com/v1/prices/'+Fid_OUT;

  try
    whPrices.execute(procedure(auxResponse:string; ARequest: TJSXMLHttpRequest)
    begin
          if auxResponse <> '' then
          begin
            jsonObj := TJSONObject.ParseJSONValue(auxResponse) as TJSONObject;
            Fid_OUT := jsonObj.Get('id').JsonValue.value;
            Fcurrency := jsonObj.Get('currency').JsonValue.value;
            Fproduct := jsonObj.Get('product').JsonValue.value;
            Funit_amount:= StrToInt(jsonObj.Get('unit_amount').JsonValue.value);
            if tJSONString(jsonObj.GetValue('nickname')) <> nil  then
               Fnickname := jsonObj.Get('nickname').JsonValue.value;
            if tJSONString(jsonObj.GetValue('recurring')) <> nil then
            begin
                Frecurring := true;
                oRecurring:=TJSONObject(jsonObj.GetValue('recurring'));
                if tJSONString(oRecurring.GetValue('interval_count')) <> nil  then
                   FrecurringInterval_count := StrtoInt(oRecurring.Get('interval_count').JsonValue.value);
                if tJSONString(oRecurring.GetValue('interval')) <> nil  then
                   FrecurringInterval := oRecurring.Get('interval').JsonValue.value;
            end
            else
                Frecurring :=false;

            FStatus_OUT := 'OK';
            FreturnMessage_OUT := auxResponse;
            if assigned(onStripePriceStatus) then
              onStripePriceStatus(sender, tsPriceGETSuccess);
          end
          else
          begin
            FStatus_OUT := 'ERROR';
            FreturnMessage_OUT := 'Connection Error';
            Fid_OUT := '0';
            if assigned(onStripePriceStatus) then
              onStripePriceStatus(sender, tsPriceNoResponse);
          end
    end)
  except
    FStatus_OUT := 'ERROR';
    jsonObj := TJSONObject.ParseJSONValue(auxResponse) as TJSONObject;
    error := TJSONObject(jsonObj.Get('error').JsonValue);
    FreturnMessage_OUT := error.Get('message').JsonValue.value;
    Fid_OUT := '0';
    if assigned(onStripePriceStatus) then
        onStripePriceStatus(sender, tsPriceStripeError);
  end;
end;

/// -----------------------------------------------------------------------------
/// <summary>
/// Procedure: TPrice.UpdateStripePrice(sender : Tobject)
/// Author: CrisScanferla
/// Date: 06-Apr-2023
/// Result: Gets 1 tax Code from Stripe
/// </summary>
/// ----------------------------------------------------------------------------
/// <remarks>
///     Ex call :  OBJPRICE.UpdateStripePrice(sender);
/// </remarks>
/// <param name="sender: Tobject">
/// </param>
/// <param name="OBJPRICE.onStripePriceStatus">
/// Your app behaviour procedure(TStripePriceStatus) to deal with each one of the  TPriceStatus.
/// </param>
/// <param name=OBJPRICE.sk >
/// string - sk from client at stripe API. There is two sk options for each client. Test SK and Live SK. Pass the correct one to access each ambient type
/// </param>
/// <param name=OBJPRICE.id_OUT >
/// string - id from price you want to update at stripe.
/// </param>
/// <param name=OBJPRICE.nickname >
/// string - Nickname to be changed.
/// </param>
///------------------------------------------------------------------------------
procedure TPrice.UpdateStripePrice(sender : Tobject);
var
  auxprice,auxResponse: String;
  jsonObj: TJSONObject;
  error: TJSONObject;
begin
  auxprice := '';
  if Trim(Fcurrency) <> '' then
     auxprice := auxprice + 'currency=' + Fcurrency + '&';
  if Trim(Fproduct) <> '' then
     auxprice := auxprice + 'product=' + Fproduct + '&';
  if Trim(Fnickname) <> '' then
     auxprice := auxprice + 'nickname=' + Fnickname + '&';

  whPrices := TWebHttpRequest.Create(self);
  whPrices.Command := httpPOST;
  whPrices.Headers.Clear;
  whPrices.Headers.Add('Cache-Control=no-cache, no-store, must-revalidate');
  whPrices.Headers.Add('Content-Type=application/x-www-form-urlencoded');
  if trim(Fsk) = '' then
   begin
      onStripePriceStatus( sender,tsPriceMissingSk);
      raise Exception.Create('');
    end
  else
    whPrices.Headers.Add('Authorization=Bearer ' + Fsk);
  if trim(Fid_OUT) = '' then
   begin
      onStripePriceStatus( sender,tsPriceMissingID);
      raise Exception.Create('');
    end
  else
  whPrices.URL := 'https://api.stripe.com/v1/prices/'+Fid_OUT;
  whPrices.PostData := auxprice;

  try
    whPrices.execute(procedure(auxResponse:string; ARequest: TJSXMLHttpRequest)
    begin
            if auxResponse <> '' then
            begin
              jsonObj := TJSONObject.ParseJSONValue(auxResponse) as TJSONObject;
              Fid_OUT := jsonObj.Get('id').JsonValue.value;
              FStatus_OUT := 'OK';
              FreturnMessage_OUT := auxResponse;
              if assigned(onStripePriceStatus) then
                onStripePriceStatus(sender, tsPriceUPDATESuccess);
            end
            else
            begin
              FStatus_OUT := 'ERROR';
              FreturnMessage_OUT := 'Connection Error';
              Fid_OUT := '0';
              if assigned(onStripePriceStatus) then
                onStripePriceStatus(sender, tsPriceNoResponse);
            end
    end)
  except
    FStatus_OUT := 'ERROR';
    jsonObj := TJSONObject.ParseJSONValue(auxResponse) as TJSONObject;
    error := TJSONObject(jsonObj.Get('error').JsonValue);
    FreturnMessage_OUT := error.Get('message').JsonValue.value;
    Fid_OUT := '0';
    if assigned(onStripePriceStatus) then
        onStripePriceStatus(sender, tsPriceStripeError);
  end;
end;

/// -----------------------------------------------------------------------------
/// <summary>
/// Procedure: TPrice.InsertStripePrice(sender : Tobject)
/// Author: CrisScanferla
/// Date: 06-Apr-2023
/// Result: Gets 1 tax Code from Stripe
/// </summary>
/// ----------------------------------------------------------------------------
/// <remarks>
///     Ex call :  OBJPRICE.InsertStripePrice(sender);
/// </remarks>
/// <param name="sender: Tobject">
/// </param>
/// <param name="OBJPRICE.onStripePriceStatus">
/// Your app behaviour procedure(TStripePriceStatus) to deal with each one of the  TPriceStatus.
/// </param>
/// <param name=OBJPRICE.sk >
/// string - sk from client at stripe API. There is two sk options for each client. Test SK and Live SK. Pass the correct one to access each ambient type
/// </param>
/// <param name=OBJPRICE.Currency >
/// string - Three-letter ISO currency code, in lowercase. Must be a supported currency.
/// </param>
/// <param name=OBJPRICE.Unit_amount >
/// integer. - The unit amount in cents to be charged. EX: 100 is 1.00
/// </param>
/// <param name=OBJPRICE.product >
/// string - product ID on Stripe API
/// </param>
/// <param name=OBJPRICE.nickname >
/// string - A brief description of the price, hidden from customers.
/// </param>
/// <param name=OBJPRICE.recurring >
/// boolean. If True, price is recurring.. so its a subscription. If it is false, it is for a 1 time payment.
/// </param>
/// <param name=OBJPRICE.recurringInterval >
/// string.use only if recurring=true. Can be day, week, month or year
/// </param>
/// <param name=OBJPRICE.recurringInterrecurringInterval_count >
/// integer.use only if recurring=true. The number of intervals. (ex... if 4 and recurringInterval = month, it will be for 4 months)
/// </param>
/// <param name=OBJPRICE.tax_behavior >
/// integer.use it to calculate taxes on stripe APP. Not required.  1 included at price 0 excluded of price  ( example price 10 tax 0.10  included - customer pays 10. excluded - customer pays 10.10)
/// </param>
///------------------------------------------------------------------------------

procedure TPrice.InsertStripePrice(sender : Tobject);
var
  auxprice,auxResponse: String;
  jsonObj: TJSONObject;
  error: TJSONObject;
begin

  auxprice := '';
  if Trim(Fcurrency) <> '' then
     auxprice := auxprice + 'currency=' + Fcurrency + '&'
  else
  begin
     onStripePriceStatus( sender,tsPriceMissingCurrency);
     raise Exception.Create('');
  end;
  if Trim(Fproduct) <> '' then
     auxprice := auxprice + 'product=' + Fproduct + '&'
  else
  begin
     onStripePriceStatus( sender,tsPriceMissingProduct);
     raise Exception.Create('');
  end;
  if InttoStr(Funit_amount) <> '' then
     auxprice := auxprice + 'unit_amount=' + IntToStr(Funit_amount) + '&'
  else
  begin
     onStripePriceStatus( sender,tsPriceMissingUnit_amount);
     raise Exception.Create('');
  end;
  if Trim(Fnickname) <> '' then
     auxprice := auxprice + 'nickname=' + Fnickname + '&';
  if Frecurring then
  begin
      if Trim(FrecurringInterval) <> '' then
         auxprice := auxprice + 'recurring[interval]=' + Trim(FrecurringInterval) + '&'
      else
      begin
         onStripePriceStatus( sender,tsPriceMissingRecurringInterval);
         raise Exception.Create('');
      end;
      if IntToStr(FrecurringInterval_count) <> '' then
         auxprice := auxprice + 'recurring[interval_count]=' + IntToStr(FrecurringInterval_count) + '&'
      else
      begin
         onStripePriceStatus( sender,tsPriceMissingRecurringInterval_count);
         raise Exception.Create('');
      end;
  end;

  whPrices := TWebHttpRequest.Create(self);
  whPrices.Command := httpPOST;
  whPrices.Headers.Clear;
  whPrices.Headers.Add('Cache-Control=no-cache, no-store, must-revalidate');
  whPrices.Headers.Add('Content-Type=application/x-www-form-urlencoded');
  if trim(Fsk) = '' then
   begin
      onStripePriceStatus( sender,tsPriceMissingSk);
      raise Exception.Create('');
    end
  else
    whPrices.Headers.Add('Authorization=Bearer ' + Fsk);
  whPrices.URL := 'https://api.stripe.com/v1/prices';
  whPrices.PostData := auxprice;

  try
    whPrices.execute(procedure(auxResponse:string; ARequest: TJSXMLHttpRequest)
    begin
        if auxResponse <> '' then
        begin
          jsonObj := TJSONObject.ParseJSONValue(auxResponse) as TJSONObject;
          Fid_OUT := jsonObj.Get('id').JsonValue.value;
          FStatus_OUT := 'OK';
          FreturnMessage_OUT := auxResponse;
          if assigned(onStripePriceStatus) then
            onStripePriceStatus(sender, tsPriceSuccess);
        end
        else
        begin
          FStatus_OUT := 'ERROR';
          FreturnMessage_OUT := 'Connection Error';
          Fid_OUT := '0';
          if assigned(onStripePriceStatus) then
            onStripePriceStatus(sender, tsPriceNoResponse);
        end
    end)
  except
    FStatus_OUT := 'ERROR';
    jsonObj := TJSONObject.ParseJSONValue(auxResponse) as TJSONObject;
    error := TJSONObject(jsonObj.Get('error').JsonValue);
    FreturnMessage_OUT := error.Get('message').JsonValue.value;
    Fid_OUT := '0';
    if assigned(onStripePriceStatus) then
        onStripePriceStatus(sender, tsPriceStripeError);
  end;

end;

/// -----------------------------------------------------------------------------
/// <summary>
/// Objects for Products. You can only use prices for simple use. But to get the list of all prices.. It should be in a product.
/// Author: CrisScanferla
/// Date: 07-Apr-2023
/// </summary>
/// ----------------------------------------------------------------------------
/// <remarks>
/// Object Description
///
/// TProductStatus = (tsProductSuccess,tsProductMissingSk,tsProductNoResponse, tsProducStripeError,tsProductMissingName,tsProductMissingID,
///                  tsProductUPDATESuccess,tsProductGETSuccess, tsProductDELETESuccess,tsProductListPricesSuccess);
/// TStripeProductStatus = procedure (sender : Tobject; errortype : TProductStatus) of object;
/// Tprices = array of TPrice;
///
///  CLASS TProduct
///
///properties:
/// sk : string - sk from client at stripe API. There is two sk options for each client. Test SK and Live SK. Pass the correct one to access each ambient type
/// id_OUT : string - if from product at stripe API
/// name: string. - name of the product
/// default_price: string. Code of the price from stripe API to be the default.
/// description: string
/// url: string. - URL for the product
/// FStatus_Out: string. OK or Erro to easy control.
/// ReturnMessage_OUT: string - complete return from Stripe
/// Prices :  Tprices. Array of all prices of the product.
/// tax_code: tax_code of object TTax_code to be used to tax rate calculations at stripe API. Not Required
/// onStripeProductStatus: TStripeProductStatus; - use it for write your app behaviour with each one of the TStripeProductStatus returned when using the class TProduct
/// </remarks>
///------------------------------------------------------------------------------

/// -----------------------------------------------------------------------------
/// <summary>
/// Procedure: TProduct.GETStripePriceList(sender : Tobject)
/// Author: CrisScanferla
/// Date: 07-Apr-2023
/// Result: Gets the list of the price for one product, at OBJPRODUCT.PRICES
/// </summary>
/// ----------------------------------------------------------------------------
/// <remarks>
///     Ex call :  OBJPRICE.GETStripePriceList(sender);
/// </remarks>
/// <param name="sender: Tobject">
/// </param>
/// <param name="OBJPRODUCT.onStripeProductStatus">
/// Your app behaviour procedure(TStripeProductStatus) to deal with each one of the  TProductStatus.
/// </param>
/// <param name=OBJPRODUCT.sk >
/// string - sk from client at stripe API. There is two sk options for each client. Test SK and Live SK. Pass the correct one to access each ambient type
/// </param>
/// <param name=OBJPRODUCT.id_OUT >
/// string - id product from Stripe API, you want the prices to be listed
/// </param>
///------------------------------------------------------------------------------

procedure TProduct.GETStripePriceList(sender : Tobject);
var
  auxResponse: String;
  jsonObj,oData,oRecurring: TJSONObject;
  error,oResponse: TJSONObject;
begin

whProducts := TWebHttpRequest.Create(self);
  whProducts.Command := httpGET;
  whProducts.Headers.Clear;
  whProducts.Headers.Add('Cache-Control=no-cache, no-store, must-revalidate');
  whProducts.Headers.Add('Content-Type=application/x-www-form-urlencoded');
  if trim(Fsk) = '' then
   begin
      onStripeProductStatus( sender,tsProductMissingSk);
      raise Exception.Create('');
    end
  else
    whProducts.Headers.Add('Authorization=Bearer ' + Fsk);
  if trim(Fid_OUT) = '' then
   begin
      onStripeProductStatus( sender,tsProductMissingID);
      raise Exception.Create('');
    end
  else
  whProducts.URL := 'https://api.stripe.com/v1/prices?active=true&product='+Fid_OUT;
  //whProducts.PostData := 'active=false&product='+Fid_OUT;

  try
    whProducts.execute(
       procedure(auxResponse:string; ARequest: TJSXMLHttpRequest)
       var oList: TJSONArray;
           oItemList :TJSONValue;
           oPrice: TPrice;
       begin
         if auxResponse <> '' then
          begin
            oResponse := TJSONObject.ParseJSONValue(auxResponse) as TJSONObject;
            oList:=tJSONArray(oResponse.GetValue('data'));
            if not(oList =nil)  then
            begin
                oPrice:=TPrice.Create(self);
                for oItemList in oList do
                begin
                   oData:=TJSONObject(oItemList);
                   jsonObj := TJSONObject.ParseJSONValue(oData.ToString) as TJSONObject;
                   oPrice.id_OUT := jsonObj.Get('id').JsonValue.value;
                   oPrice.currency := jsonObj.Get('currency').JsonValue.value;
                   if tJSONString(jsonObj.GetValue('nickname')) <> nil then
                      oPrice.nickname := jsonObj.Get('nickname').JsonValue.value
                   else
                      oPrice.nickname := '';
                   oPrice.unit_amount := StrToInt(jsonObj.Get('unit_amount').JsonValue.value);
                   if (tJSONString(jsonObj.GetValue('recurring')) <> nil) and (jsonObj.Get('type').JsonValue.value='recurring')then
                   begin
                      oPrice.recurring:=true;
                      if (oRecurring <> nil) then
                      begin
                          if tJSONString(oRecurring.GetValue('interval_count')) <> nil  then
                             oPrice.recurringInterval_count := StrtoInt(oRecurring.Get('interval_count').JsonValue.value)
                          else
                             oPrice.recurringInterval_count := 0;
                          if tJSONString(oRecurring.GetValue('interval')) <> nil  then
                             oPrice.recurringInterval := oRecurring.Get('interval').JsonValue.value
                          else
                             oPrice.recurringInterval := ''
                      end
                   end
                   else
                      oPrice.recurring:=false;
                   PutPrice(oPrice);
                end;
                FStatus_OUT := 'OK';
                FreturnMessage_OUT := auxResponse;
                if assigned(onStripeProductStatus) then
                  onStripeProductStatus(sender, tsProductListPricesSuccess);
            end
            else begin
                FStatus_OUT := 'OK';
                FreturnMessage_OUT := 'Product with no price defined';
                if assigned(onStripeProductStatus) then
                  onStripeProductStatus(sender, tsProductListPricesSuccess);
            end;
          end
          else
          begin
            FStatus_OUT := 'ERROR';
            FreturnMessage_OUT := 'Connection Error';
            Fid_OUT := '0';
            if assigned(onStripeProductStatus) then
              onStripeProductStatus(sender, tsProductNoResponse);
          end;
       end)
  except
    FStatus_OUT := 'ERROR';
    jsonObj := TJSONObject.ParseJSONValue(auxResponse) as TJSONObject;
    error := TJSONObject(jsonObj.Get('error').JsonValue);
    FreturnMessage_OUT := error.Get('message').JsonValue.value;
    Fid_OUT := '0';
    if assigned(onStripeProductStatus) then
        onStripeProductStatus(sender, tsProducStripeError);
  end;
end;

/// -----------------------------------------------------------------------------
/// <summary>
/// Objects for Customers.
/// Author: CrisScanferla
/// Date: 06-Apr-2023
/// </summary>
/// ----------------------------------------------------------------------------
/// <remarks>
/// Object Description
///
///  TCustomerStatus = (tsCustomerSuccess,tsCustomerMissingSk,tsCustomerNoResponse, tsCustomerStripeError,tsCustomerMissingID,tsGETCustomerSuccess,
///                     tsDELETECustomerSuccess,tsUPDATECustomerSuccess);
///  TStripeCustomerStatus = procedure (sender : Tobject; errortype : TCustomerStatus) of object;
///
///  CLASS TCustomer
///
///properties:
/// sk : string - sk from client at stripe API. There is two sk options for each client. Test SK and Live SK. Pass the correct one to access each ambient type
/// id_OUT : string - if from customer at stripe API
/// name: string. - name of the customer
/// phone: string.
/// email: string.
/// FStatus_Out: string. OK or Error to easy control.
/// ReturnMessage_OUT: string - complete return from Stripe
/// onStripeCustomerStatus: TStripeCustomerStatus;; - use it for write your app behaviour with each one of the TCustomerStatus  returned when using the class TCustomer
/// </remarks>
///------------------------------------------------------------------------------

/// -----------------------------------------------------------------------------
/// <summary>
/// Procedure: TPCustomer.UPDATECustomer(sender : Tobject)
/// Author: CrisScanferla
/// Date: 06-Apr-2023
/// Result: Updates information of the customer at Stripe API
/// </summary>
/// ----------------------------------------------------------------------------
/// <remarks>
///     Ex call :  OBJCUSTOMER.UPDATECustomer(sender);
/// </remarks>
/// <param name="sender: Tobject">
/// </param>
/// <param name="OBJCUSTOMER.onStripeCustomerStatus">
/// Your app behaviour procedure(TStripeCustomerStatus ) to deal with each one of the  TCustomerStatus .
/// </param>
/// <param name=OBJCUSTOMER.sk >
/// string - sk from client at stripe API. There is two sk options for each client. Test SK and Live SK. Pass the correct one to access each ambient type
/// </param>
/// <param name=OBJCUSTOMER.id_OUT >
/// string - id customer from Stripe API, you want to update informations
/// </param>
/// <param name=OBJCUSTOMER.name>
/// string - name of the customer to be updated at stripe API
/// </param>
/// <param name=OBJCUSTOMER.phone>
/// string - phone of the customer to be updated at stripe API
/// </param>
/// <param name=OBJCUSTOMER.email>
/// string - email of the customer to be updated at stripe API
/// </param>
///------------------------------------------------------------------------------

procedure TCustomer.UPDATECustomer(sender: TObject);
var
    customer,auxResponse: String;
    jsonObj: TJSONObject;
    error: TJSONObject;
begin

  customer := '';
  if Trim(FName) <> '' then
     customer := customer + 'name=' + Fname + '&';
  if Trim(Femail) <> '' then
     customer := customer + 'email=' + Femail + '&';
  if Trim(Fphone) <> '' then
     customer := customer + 'phone=' + Fphone + '&';

  whReqCustomer := TWebHttpRequest.Create(self);
  whReqCustomer.Command := httpPOST;
  whReqCustomer.Headers.Clear;
  whReqCustomer.Headers.Add('Cache-Control=no-cache, no-store, must-revalidate');
  whReqCustomer.Headers.Add('Content-Type=application/x-www-form-urlencoded');
  if trim(Fsk) = '' then
   begin
      onStripeCustomerStatus( sender,tsCustomerMissingSk);
      raise Exception.Create('');
    end
  else
    whReqCustomer.Headers.Add('Authorization=Bearer ' + Fsk);
  if trim(Fid_OUT) = '' then
   begin
      onStripeCustomerStatus( sender,tsCustomerMissingID);
      raise Exception.Create('');
    end
  else
     whReqCustomer.URL := 'https://api.stripe.com/v1/customers/'+Fid_OUT;
  whReqCustomer.PostData := customer;

  try
    whReqCustomer.execute(procedure(auxResponse:string; ARequest: TJSXMLHttpRequest)
    begin
        if auxResponse <> '' then
        begin
          jsonObj := TJSONObject.ParseJSONValue(auxResponse) as TJSONObject;
          Fid_OUT := jsonObj.Get('id').JsonValue.value;
          FStatus_OUT := 'OK';
          FreturnMessage_OUT := auxResponse;
          if assigned(onStripeCustomerStatus) then
            onStripeCustomerStatus(sender, tsUPDATECustomerSuccess);
        end
        else
        begin
          FStatus_OUT := 'ERROR';
          FreturnMessage_OUT := 'Connection Error';
          Fid_OUT := '0';
          if assigned(onStripeCustomerStatus) then
            onStripeCustomerStatus(sender, tsCustomerNoResponse);
        end
    end)
  except
    FStatus_OUT := 'ERROR';
    jsonObj := TJSONObject.ParseJSONValue(auxResponse) as TJSONObject;
    error := TJSONObject(jsonObj.Get('error').JsonValue);
    FreturnMessage_OUT := error.Get('message').JsonValue.value;
    Fid_OUT := '0';
    if assigned(onStripeCustomerStatus) then
        onStripeCustomerStatus(sender, tsCustomerStripeError);
  end;
end;


/// -----------------------------------------------------------------------------
/// <summary>
/// Procedure: TPCustomer.DELETECustomer(sender : Tobject)
/// Author: CrisScanferla
/// Date: 06-Apr-2023
/// Result: Deletes information of the customer from stripe API
/// </summary>
/// ----------------------------------------------------------------------------
/// <remarks>
///     Ex call :  OBJCUSTOMER.DELETECustomer(sender);
/// </remarks>
/// <param name="sender: Tobject">
/// </param>
/// <param name="OBJCUSTOMER.onStripeCustomerStatus">
/// Your app behaviour procedure(TStripeCustomerStatus ) to deal with each one of the  TCustomerStatus .
/// </param>
/// <param name=OBJCUSTOMER.sk >
/// string - sk from client at stripe API. There is two sk options for each client. Test SK and Live SK. Pass the correct one to access each ambient type
/// </param>
/// <param name=OBJCUSTOMER.id_OUT >
/// string - id customer from Stripe API, you want to delete
/// </param>
///------------------------------------------------------------------------------
procedure TCustomer.DELETECustomer(sender: TObject);
var
    auxResponse: String;
    jsonObj: TJSONObject;
    error: TJSONObject;

begin
  whReqCustomer := TWebHttpRequest.Create(self);
  whReqCustomer.Command := httpDELETE;
  whReqCustomer.Headers.Clear;
  whReqCustomer.Headers.Add('Cache-Control=no-cache, no-store, must-revalidate');
  whReqCustomer.Headers.Add('Content-Type=application/x-www-form-urlencoded');
  if trim(Fsk) = '' then
   begin
      onStripeCustomerStatus( sender,tsCustomerMissingSk);
      raise Exception.Create('');
    end
  else
    whReqCustomer.Headers.Add('Authorization=Bearer ' + Fsk);
  if trim(Fid_OUT) = '' then
   begin
      onStripeCustomerStatus( sender,tsCustomerMissingID);
      raise Exception.Create('');
    end
  else
     whReqCustomer.URL := 'https://api.stripe.com/v1/customers/'+Fid_OUT;
  try
    //req := await(TJSXMLHttpRequest, whReqCustomer.Perform());
    whReqCustomer.execute(procedure(auxResponse:string; ARequest: TJSXMLHttpRequest)
    begin
        if auxResponse <> '' then
        begin
          jsonObj := TJSONObject.ParseJSONValue(auxResponse) as TJSONObject;
          Fid_OUT := jsonObj.Get('id').JsonValue.value;
          if jsonObj.Get('deleted').JsonValue.value='true' then
             FStatus_OUT := 'OK'
          else
             FStatus_OUT := 'ERRO';
          FreturnMessage_OUT := auxResponse;
          if assigned(onStripeCustomerStatus) then
            onStripeCustomerStatus(sender, tsDELETECustomerSuccess);
        end
        else
        begin
          FStatus_OUT := 'ERROR';
          FreturnMessage_OUT := 'Connection Error';
          Fid_OUT := '0';
          if assigned(onStripeCustomerStatus) then
            onStripeCustomerStatus(sender, tsCustomerNoResponse);
        end
    end)
  except
    FStatus_OUT := 'ERROR';
    jsonObj := TJSONObject.ParseJSONValue(auxResponse) as TJSONObject;
    error := TJSONObject(jsonObj.Get('error').JsonValue);
    FreturnMessage_OUT := error.Get('message').JsonValue.value;
    Fid_OUT := '0';
    if assigned(onStripeCustomerStatus) then
        onStripeCustomerStatus(sender, tsCustomerStripeError);
  end;
end;

/// -----------------------------------------------------------------------------
/// <summary>
/// Procedure: TPCustomer.GETCustomer(sender : Tobject)
/// Author: CrisScanferla
/// Date: 06-Apr-2023
/// Result: Gets Customer information from Stripe API
/// </summary>
/// ----------------------------------------------------------------------------
/// <remarks>
///     Ex call :  OBJCUSTOMER.UPDATECustomer(sender);
/// </remarks>
/// <param name="sender: Tobject">
/// </param>
/// <param name="OBJCUSTOMER.onStripeCustomerStatus">
/// Your app behaviour procedure(TStripeCustomerStatus ) to deal with each one of the  TCustomerStatus .
/// </param>
/// <param name=OBJCUSTOMER.sk >
/// string - sk from client at stripe API. There is two sk options for each client. Test SK and Live SK. Pass the correct one to access each ambient type
/// </param>
/// <param name=OBJCUSTOMER.id_OUT >
/// string - id customer from Stripe API, you want to get informations
/// </param>
///------------------------------------------------------------------------------

procedure TCustomer.GETCustomer(sender: TObject);
var
    auxResponse: String;
    jsonObj: TJSONObject;
    error: TJSONObject;
begin
  whReqCustomer := TWebHttpRequest.Create(self);
  whReqCustomer.Command := httpGET;
  whReqCustomer.Headers.Clear;
  whReqCustomer.Headers.Add('Cache-Control=no-cache, no-store, must-revalidate');
  whReqCustomer.Headers.Add('Content-Type=application/x-www-form-urlencoded');
  if trim(Fsk) = '' then
   begin
      onStripeCustomerStatus( sender,tsCustomerMissingSk);
      raise Exception.Create('');
    end
  else
    whReqCustomer.Headers.Add('Authorization=Bearer ' + Fsk);
  if trim(Fid_OUT) = '' then
   begin
      onStripeCustomerStatus( sender,tsCustomerMissingID);
      raise Exception.Create('');
    end
  else
     whReqCustomer.URL := 'https://api.stripe.com/v1/customers/'+Fid_OUT;
  try
    //req := await(TJSXMLHttpRequest, whReqCustomer.Perform());
    whReqCustomer.execute(procedure(auxResponse:string; ARequest: TJSXMLHttpRequest)
    begin
        if auxResponse <> '' then
        begin
          jsonObj := TJSONObject.ParseJSONValue(auxResponse) as TJSONObject;
          Fid_OUT := jsonObj.Get('id').JsonValue.value;
          FName := jsonObj.Get('name').JsonValue.value;
          Femail := jsonObj.Get('email').JsonValue.value;
          Fphone := jsonObj.Get('phone').JsonValue.value;
          FStatus_OUT := 'OK';
          FreturnMessage_OUT := auxResponse;
          if assigned(onStripeCustomerStatus) then
            onStripeCustomerStatus(sender, tsGETCustomerSuccess);
        end
        else
        begin
          FStatus_OUT := 'ERROR';
          FreturnMessage_OUT := 'Connection Error';
          Fid_OUT := '0';
          if assigned(onStripeCustomerStatus) then
            onStripeCustomerStatus(sender, tsCustomerNoResponse);
        end
    end)
  except
    FStatus_OUT := 'ERROR';
    jsonObj := TJSONObject.ParseJSONValue(auxResponse) as TJSONObject;
    error := TJSONObject(jsonObj.Get('error').JsonValue);
    FreturnMessage_OUT := error.Get('message').JsonValue.value;
    Fid_OUT := '0';
    if assigned(onStripeCustomerStatus) then
        onStripeCustomerStatus(sender, tsCustomerStripeError);
  end;
end;

/// -----------------------------------------------------------------------------
/// <summary>
/// Procedure: TPCustomer.InsertStripeCustomer(sender : Tobject)
/// Author: CrisScanferla
/// Date: 06-Apr-2023
/// Result: Inserts Customer Information to Stripe API with Charging objective.
/// </summary>
/// ----------------------------------------------------------------------------
/// <remarks>
///     Ex call :  OBJCUSTOMER.InsertStripeCustomer(sender);
/// </remarks>
/// <param name="sender: Tobject">
/// </param>
/// <param name="OBJCUSTOMER.onStripeCustomerStatus">
/// Your app behaviour procedure(TStripeCustomerStatus ) to deal with each one of the  TCustomerStatus .
/// </param>
/// <param name=OBJCUSTOMER.sk >
/// string - sk from client at stripe API. There is two sk options for each client. Test SK and Live SK. Pass the correct one to access each ambient type
/// </param>
/// <param name=OBJCUSTOMER.id_OUT >
/// string - id customer from Stripe API, you want to update informations
/// </param>
/// <param name=OBJCUSTOMER.name>
/// string - name of the customer
/// </param>
/// <param name=OBJCUSTOMER.phone>
/// string - phone of the customer    not required
/// </param>
/// <param name=OBJCUSTOMER.email>
/// string - email of the customer    not required
/// </param>
///------------------------------------------------------------------------------

procedure TCustomer.InsertStripeCustomer(sender : Tobject);
var
  customer, auxResponse: String;
  jsonObj: TJSONObject;
  error: TJSONObject;
begin

  customer := '';
  if Trim(FName) <> '' then
     customer := customer + 'name=' + Fname + '&';
  if Trim(Femail) <> '' then
     customer := customer + 'email=' + Femail + '&';
  if Trim(Fphone) <> '' then
     customer := customer + 'phone=' + Fphone + '&';

  whReqCustomer := TWebHttpRequest.Create(self);

  whReqCustomer.Command := httpPOST;
  whReqCustomer.Headers.Clear;
  whReqCustomer.Headers.Add('Cache-Control=no-cache, no-store, must-revalidate');
  whReqCustomer.Headers.Add('Content-Type=application/x-www-form-urlencoded');
  if trim(Fsk) = '' then
   begin
      onStripeCustomerStatus( sender,tsCustomerMissingSk);
      raise Exception.Create('');
    end
  else
    whReqCustomer.Headers.Add('Authorization=Bearer ' + Fsk);
  whReqCustomer.URL := 'https://api.stripe.com/v1/customers';
  whReqCustomer.PostData := customer;

  try
    //req := await(TJSXMLHttpRequest, whReqCustomer.Perform());
    whReqCustomer.execute(procedure(auxResponse:string; ARequest: TJSXMLHttpRequest)
    begin
        if auxResponse <> '' then
        begin
          jsonObj := TJSONObject.ParseJSONValue(auxResponse) as TJSONObject;
          Fid_OUT := jsonObj.Get('id').JsonValue.value;
          FStatus_OUT := 'OK';
          FreturnMessage_OUT := auxResponse;
          if assigned(onStripeCustomerStatus) then
            onStripeCustomerStatus(sender, tsCustomerSuccess);
        end
        else
        begin
          FStatus_OUT := 'ERROR';
          FreturnMessage_OUT := 'Connection Error';
          Fid_OUT := '0';
          if assigned(onStripeCustomerStatus) then
            onStripeCustomerStatus(sender, tsCustomerNoResponse);
        end
    end)
  except
    FStatus_OUT := 'ERROR';
    jsonObj := TJSONObject.ParseJSONValue(auxResponse) as TJSONObject;
    error := TJSONObject(jsonObj.Get('error').JsonValue);
    FreturnMessage_OUT := error.Get('message').JsonValue.value;
    Fid_OUT := '0';
    if assigned(onStripeCustomerStatus) then
        onStripeCustomerStatus(sender, tsCustomerStripeError);
  end;

end;



/// -----------------------------------------------------------------------------
/// <summary>
/// Aditional Objects for Checkout - Line Items
/// Author: CrisScanferla
/// Date: 08-Apr-2023
/// </summary>
/// ----------------------------------------------------------------------------
/// <remarks>
/// Object Description
/// CLASS Tline_items
///
///properties:
/// id_price: string. Id of the TPrice at Stripe API.
/// quantity: integer
/// TaxRates: array of tax rates to be applyed to this item at this operation.
///
/// Tarray_line_items = array of Tline_items;
/// in a checkout you can have 1 or more itens.
/// to add a line item use OBJCHECKOUT.SETline_items(const Value: Tline_items);
/// Each Item can be charged differently and each Item can have more than 1 tax_Rate.
/// To add a Tax_rate to the an item use: ITEMOBJ.SETTaxRates(id tax_Rate). Use it one time  for each tax rate you need to add to one item.
/// </remarks>
///------------------------------------------------------------------------------
procedure Tcheckout.SETline_items(const Value: Tline_items);
var
  vIndex : integer;
begin
   vIndex := length(Fline_items);
   SetLength(Fline_items,vIndex+1);
   Fline_items[vIndex]:= Tline_items.Create(self);
   Fline_items[vIndex].Fid_price := Value.Fid_price;
   Fline_items[vIndex].Fquantity := Value.Fquantity;
   Fline_items[vIndex].FTaxRates := Value.FTaxRates;
end;



function Tcheckout.GETline_items: Tarray_line_items;
begin
 result := Fline_items;
end;


procedure Tline_items.SETTaxRates(const Value:string);
var
  vIndex : integer;
begin
   vIndex := length(FTaxRates);
   SetLength(FTaxRates,vIndex+1);
   FTaxRates[vIndex]:= Value;
end;

function Tline_items.GETTaxRates: tTaxRates;
begin
 result := FTaxRates;
end;

/// -----------------------------------------------------------------------------
/// <summary>
/// Objects for Checkout.
/// Author: CrisScanferla
/// Date: 08-Apr-2023
/// </summary>
/// ----------------------------------------------------------------------------
/// <remarks>
/// Object Description
///
///    TCheckoutStatus = (tsCheckoutSuccess, tsCheckoutMissingID,tsCheckoutMissingSk,tsCheckoutNoResponse, tsCheckoutStripeError,
///                     tsCheckoutMissingItemPrice, tsCheckoutMissingItemQuantity,tsCheckoutMissingMode,
///                     tsCheCkoutMissingSuccess_URL,tsCheckoutMissingItems);
///  TStripeCheckoutStatus = procedure (Sender: Tobject; errortype : TCheckoutStatus) of object;
///
///  CLASS Tcheckout
///
///  The checkout is the object that starts all charges transactions, 1 time payment or subscription.
///
///properties:
/// sk : string - sk from client at stripe API. There is two sk options for each client. Test SK and Live SK. Pass the correct one to access each ambient type
/// id_OUT : string - if from customer at stripe API
/// URL_OUT: string - URL for this charge. Call it and Stripe APP will charge your client, showing the cart, take card information (number, val, security code) and client address when needed.
/// line_items:  array of TLine_items. Each checkout can have one or more item to be charged.
/// mode: string. payment, setup, subscription. When subscription, has to put a price that has recurring type of charge  and interval.
/// When its a payment, the price has to be an one time price.
/// currency: string - Three-letter ISO currency code, in lowercase. Must be a supported currency.
/// customer: string. - id of the customer   at stripe API
/// success_URL: string. URL address to redirect when payment success.
/// payment_intent: string. id of payment generated (when payment mode) . Use it to see payment detail and status.
/// payment_status: string. status payment generated (when payment mode) . Use it to see payment status.
/// subscription:string; id of subscription generated (when subscription mode) . Use it to see subscription detail and  status.
/// Status_Out: string. OK or Error to easy control.
/// automatic_tax : integer;  use '1' to automatic tax calculation. for automatic tax, customer has to give address and tax rates for this location have to be configured.
/// billing_address_collection:string;  for billing address collection (auto and required)
/// allow_promotion_codes: integer; //use '1' to use promotion codes
/// trial_period_days : integer; //number of days for trial
/// trial_end_behavior:string; // trial behaviour when trial period ends. Can be pause, cancel or create_invoice.
/// Amount_total:integer;
/// amount_discount: integer; total discount
/// amount_shipping: integer; total shipping
/// amount_tax: integer;  total tax
/// ReturnMessage_OUT: string - complete return from Stripe
/// onStripeCustomerStatus: TStripeCustomerStatus;; - use it for write your app behaviour with each one of the TCustomerStatus  returned when using the class TCustomer
/// </remarks>
///------------------------------------------------------------------------------

/// -----------------------------------------------------------------------------
/// <summary>
/// Procedure: Tcheckout.InsertStripeCheckout(sender : Tobject)
/// Author: CrisScanferla
/// Date: 08-Apr-2023
/// Result: Insert a checkout for charge. Will result in a subscription (when subscription mode) or a payment (when payment mode)
/// get the subscription o payment id to check the details and status of the charge made.
/// </summary>
/// ----------------------------------------------------------------------------
/// <remarks>
///     Ex call :  OBJCHECKOUT.InsertStripeCheckout(sender);
/// </remarks>
/// <param name="sender: Tobject">
/// </param>
/// <param name="OBJCHECKOUT.onStripeCheckoutStatus">
/// Your app behaviour procedure(TStripeCheckoutStatus ) to deal with each one of the  TCheckoutStatus .
/// </param>
/// <param name=OBJCHECKOUT.sk >
/// string - sk from client at stripe API. There is two sk options for each client. Test SK and Live SK. Pass the correct one to access each ambient type
/// </param>
/// <param name=OBJCHECKOUT.mode >
/// string - (subscription or payment)
/// </param>
/// <param name=OBJCHECKOUT.currency>
/// string -Three-letter ISO currency code, in lowercase. Must be a supported currency.
/// </param>
/// <param name=OBJCHECKOUT.customer>
/// string - id of the customer t stripe API
/// </param>
/// <param name=OBJCHECKOUT.success_url>
/// string - URL address to redirect when payment success.
/// </param>
/// <param name=OBJCHECKOUT.LineItems>
/// array of TLine_Items. Create the object OBJECTLINEITEM and use SETline_items(OBJECTLINEITEM) to add items to the checkout.
/// </param>
/// <param name=OBJCHECKOUT.automatic_tax>
/// string - 1 to automatic tax calculation, 0 to pass taxes (object TTax_Rate).
/// </param>
/// <param name=OBJCHECKOUT.allow_promotion_codes>
/// string - 1 to allow promotion codes and 0 dont allow. Checkout will ask for the promotion code and apply discount.
/// </param>
/// <param name=OBJCHECKOUT.billing_address_collection>
/// string - use auto or required, when needed. Checkout can ask the address of the customer to be informed.
/// </param>
///------------------------------------------------------------------------------

procedure Tcheckout.InsertStripeCheckout(sender : Tobject);
var
  checkout,item: String;
  jsonObj: TJSONObject;
  i,j:integer;
begin
  checkout := '';
  if Fmode <> '' then
    checkout := checkout + 'mode=' + Fmode + '&'
  else
  begin
    onStripeCheckoutStatus(sender,tsCheckoutMissingMode);
    raise Exception.Create('');
  end;
  if Fcurrency <> '' then
    checkout := checkout + 'currency=' + Fcurrency + '&';
  if Fcustomer <> '' then
    checkout := checkout + 'customer=' + Fcustomer + '&';
  if Fsuccess_url <> '' then
    checkout := checkout + 'success_url=' + Fsuccess_url + '&'
  else
  begin
    onStripeCheckoutStatus(sender,tsCheCkoutMissingSuccess_URL);
    raise Exception.Create('');
  end;
  if fallow_promotion_codes=1 then
      checkout := checkout + 'allow_promotion_codes=true&';
  if Fautomatic_tax=1 then begin
      checkout := checkout + 'automatic_tax[enabled]=true&';
      if Fbilling_address_collection<>'' then
      begin
          checkout := checkout + 'billing_address_collection='+Fbilling_address_collection+'&';
          checkout := checkout + 'customer_update[address]=auto&';
      end
      else
          checkout := checkout + 'billing_address_collection=auto&';
  end;
  if (Ftrial_period_days>0) then
  begin
      checkout := checkout + 'subscription_data[trial_period_days]='+IntToStr(Ftrial_period_days)+'&';
      if Ftrial_end_behavior<>'' then
         checkout := checkout + 'subscription_data[trial_settings[end_behavior[missing_payment_method]]]=' + Ftrial_end_behavior+'&'
      else
          checkout := checkout + 'subscription_data[trial_settings[end_behavior[missing_payment_method]]]=pause&'
  end;
  if length(Fline_items) > 0 then
  begin
       for i := 0 to length(Fline_items)-1 do
       begin
           item := '';
           if Fline_items[i].Fid_price <> '' then
               item :=item + 'line_items['+inttostr(i)+'][price] =' + Fline_items[i].Fid_price+ '&'
           else
           begin
              onStripeCheckoutStatus(sender,tsCheckoutMissingItemPrice);
              raise Exception.Create('');
           end;

           if Fline_items[i].Fquantity.ToString <> '' then
               item :=item + 'line_items['+inttostr(i)+'][quantity] =' + Fline_items[i].Fquantity.ToString+ '&'
           else
           begin
              onStripeCheckoutStatus(sender,tsCheckoutMissingItemQuantity);
              raise Exception.Create('');
           end;
           if length(Fline_items[i].FTaxRates) >0  then
              for j := 0 to length(Fline_items[i].FTaxRates)-1 do
                  item := item + 'line_items['+inttostr(i)+'][tax_rates]['+inttostr(j)+']='+Fline_items[i].FTaxRates[j];

           checkout := checkout + item;
       end;
  end
  else
  begin
    onStripeCheckoutStatus(sender,tsCheckoutMissingItems);
    raise Exception.Create('');
  end;


  whReqCheckout := TWebHttpRequest.Create(self);
  whReqCheckout.Command := httpPOST;
  whReqCheckout.Headers.Clear;
  whReqCheckout.Headers.Add('Cache-Control=no-cache, no-store, must-revalidate');
  whReqCheckout.Headers.Add('Content-Type=application/x-www-form-urlencoded');
  if trim(Fsk) = '' then
   begin
      onStripeCheckoutStatus(sender,tsCheckoutMissingSk);
      raise Exception.Create('');
    end
  else
    whReqCheckout.Headers.Add('Authorization=Bearer ' + Fsk);
  whReqCheckout.URL := 'https://api.stripe.com/v1/checkout/sessions';
  whReqCheckout.PostData := checkout;

  try
    whReqCheckout.execute(procedure(auxResponse:string; ARequest: TJSXMLHttpRequest)
    begin
        if auxResponse <> '' then
        begin
          showmessage(auxresponse);
          jsonObj := TJSONObject.ParseJSONValue(auxResponse) as TJSONObject;
          Fid_OUT := jsonObj.Get('id').JsonValue.value;
          FStatus_OUT := 'OK';
          Furl_OUT := jsonObj.Get('url').JsonValue.value;
          if Fmode='payment' then
             Fpayment_intent := jsonObj.Get('payment_intent').JsonValue.Value;
          FreturnMessage_OUT := auxResponse;
          if assigned(onStripeCheckoutStatus) then
            onStripeCheckoutStatus(sender, tsCheckoutSuccess);
        end
        else
        begin
          FStatus_OUT := 'ERROR';
          FreturnMessage_OUT := 'Connection error.';
          Fid_OUT := '0';
          if assigned(onStripeCheckoutStatus) then
            onStripeCheckoutStatus(sender, tsCheckoutNoResponse);
        end
    end)
  except
    FStatus_OUT := 'ERROR';
    FreturnMessage_OUT := jsonObj.Get('error').JsonValue.value;
    Fid_OUT := '0';
    if assigned(onStripeCheckoutStatus) then
        onStripeCheckoutStatus(sender, tsCheckoutStripeError);
  end;
end;

/// -----------------------------------------------------------------------------
/// <summary>
/// Procedure: Tcheckout.InsertStripeCheckout(sender : Tobject)
/// Author: CrisScanferla
/// Date: 08-Apr-2023
/// Result: Details of the checkout.
/// </summary>
/// ----------------------------------------------------------------------------
/// <remarks>
///     Ex call :  OBJCHECKOUT.GETStripeCheckout(sender);
/// </remarks>
/// <param name="sender: Tobject">
/// </param>
/// <param name="OBJCHECKOUT.onStripeCheckoutStatus">
/// Your app behaviour procedure(TStripeCheckoutStatus ) to deal with each one of the  TCheckoutStatus .
/// </param>
/// <param name=OBJCHECKOUT.sk >
/// string - sk from client at stripe API. There is two sk options for each client. Test SK and Live SK. Pass the correct one to access each ambient type
/// </param>
/// <param name=OBJCHECKOUT.id_OUT>
/// string - id of the checkout at Stripe API
/// </param>
///------------------------------------------------------------------------------

procedure Tcheckout.GETStripeCheckout(sender : Tobject);
var
  auxResponse: String;
  jsonObj: TJSONObject;
  error, oTotal_Details: TJSONObject;
begin

  whReqCheckout := TWebHttpRequest.Create(self);

  whReqCheckout.Command := httpGET;
  whReqCheckout.Headers.Clear;
  whReqCheckout.Headers.Add('Cache-Control=no-cache, no-store, must-revalidate');
  whReqCheckout.Headers.Add('Content-Type=application/x-www-form-urlencoded');
  if trim(Fsk) = '' then
   begin
      onStripeCheckoutStatus(sender,tsCheckoutMissingSk);
      raise Exception.Create('');
    end
  else
      whReqCheckout.Headers.Add('Authorization=Bearer ' + Fsk);
  if trim(Fid_OUT) = '' then
   begin
      onStripeCheckoutStatus( sender,tsCheckoutMissingID);
      raise Exception.Create('');
    end
  else
     whReqCheckout.URL := 'https://api.stripe.com/v1/checkout/sessions/'+Fid_OUT;

  try
    whReqCheckout.execute(procedure(auxResponse:string; ARequest: TJSXMLHttpRequest)
    begin
        if auxResponse <> '' then
        begin
          jsonObj := TJSONObject.ParseJSONValue(auxResponse) as TJSONObject;
          FStatus_OUT := 'OK';
          if jsonObj.GetValue('amount_total') <> nil then
             FAmount_total := StrToInt(jsonObj.Get('amount_total').JsonValue.Value);
          oTotal_Details :=  TJSONObject(jsonObj.GetValue('total_details'));
          if oTotal_Details <> nil  then
          begin
              if oTotal_Details.GetValue('amount_discount') <> nil then
                  Famount_discount := StrToInt(oTotal_Details.Get('amount_discount').JsonValue.Value);
              if oTotal_Details.GetValue('amount_shipping') <> nil then
                  Famount_shipping := StrToInt(oTotal_Details.Get('amount_shipping').JsonValue.Value);
              if oTotal_Details.GetValue('amount_tax') <> nil then
                  Famount_tax := StrToInt(oTotal_Details.Get('amount_tax').JsonValue.Value);
          end;
          if jsonObj.GetValue('payment_status') <> nil then
             Fpayment_status := jsonObj.Get('payment_status').JsonValue.Value;
          if jsonObj.GetValue('subscription') <> nil then
             Fsubscription := jsonObj.Get('subscription').JsonValue.value;
          FreturnMessage_OUT := auxResponse;
          if assigned(onStripeCheckoutStatus) then
            onStripeCheckoutStatus(sender, tsCheckoutSuccess);
        end
        else
        begin
          FStatus_OUT := 'ERROR';
          FreturnMessage_OUT := 'Connection Error';
           if assigned(onStripeCheckoutStatus) then
            onStripeCheckoutStatus(sender, tsCheckoutNoResponse);
        end
    end)
  except
    FStatus_OUT := 'ERROR';
    jsonObj := TJSONObject.ParseJSONValue(auxResponse) as TJSONObject;
    error := TJSONObject(jsonObj.Get('error').JsonValue);
    FreturnMessage_OUT := error.Get('message').JsonValue.value;
    if assigned(onStripeCheckoutStatus) then
        onStripeCheckoutStatus(sender, tsCheckoutStripeError);
  end;
end;



/// -----------------------------------------------------------------------------
/// <summary>
/// Procedure: TPayment.GETPaymentDetails(sender : Tobject)
/// Author: CrisScanferla
/// Date: 12-May-2023
/// Result: Get Payment Details as status, value, discounts and others
/// </summary>
/// ----------------------------------------------------------------------------
/// <remarks>
///     Ex call :  OBJPAYMENT.GETPaymentDetails(sender);
/// </remarks>
/// <param name="sender: Tobject">
/// </param>
/// <param name="OBJPAYMENT.onStripePaymentStatus">
///TPaymentStatus = (tsPaymentSuccess,tsPaymentMissingSk,tsPaymentNoResponse, tsPaymentStripeError);
///  TStripePaymentStatus = procedure (sender : Tobject; errortype : TPaymentStatus) of object;
/// Your app behaviour procedure(TStripePaymentStatus ) to deal with each one of the  TPaymentStatus .
/// </param>
/// <param name=OBJPAYMENT.sk >
/// string - sk from client at stripe API. There is two sk options for each client. Test SK and Live SK. Pass the correct one to access each ambient type
/// </param>
/// <param name=OBJPAYMENT.client_secret >
/// string - id of the payment at stripe APP. You can get this ID on InsertCheckout Response, when OBJCheckout.mode=Payment
/// </param>
///------------------------------------------------------------------------------


procedure TPayment.GETPaymentDetails(sender : Tobject);
var
  auxResponse: String;
  jsonObj,oData,oCharge: TJSONObject;
  error: TJSONObject;

begin

  whGetPayment := TWebHttpRequest.Create(self);

  whGetPayment.Command := httpGET;
  whGetPayment.Headers.Clear;
  whGetPayment.Headers.Add('Cache-Control=no-cache, no-store, must-revalidate');
  whGetPayment.Headers.Add('Content-Type=application/x-www-form-urlencoded');
  if trim(Fsk) = '' then
   begin
      onStripePaymentStatus(sender,tsPaymentMissingSk);
      raise Exception.Create('');
    end
  else
  whGetPayment.Headers.Add('Authorization=Bearer ' + Fsk);
  whGetPayment.URL := 'https://api.stripe.com/v1/payment_intents/'+Fclient_secret;

  try
    //req := await(TJSXMLHttpRequest, whGetPayment.Perform());
    whGetPayment.execute(procedure(auxResponse:string; ARequest: TJSXMLHttpRequest)
    var   oList: TJSONArray;
          oItemList :TJSONValue;
    begin
        if auxResponse <> '' then
        begin
          jsonObj := TJSONObject.ParseJSONValue(auxResponse) as TJSONObject;
          Famount := StrToint(jsonObj.Get('amount').JsonValue.value);
          FStatus_OUT := 'OK';
          FreturnMessage_OUT := auxResponse;
          customer:= jsonObj.Get('customer').JsonValue.value;
          Fstatus := jsonObj.Get('status').JsonValue.value;
          if jsonObj.GetValue('cancel_url') <> nil then
              FcancelURL := jsonObj.Get('cancel_url').JsonValue.value;
          if jsonObj.GetValue('cancellation_reason') <> nil then
              Fcancelation_Reason := jsonObj.Get('cancellation_reason').JsonValue.value;
          oData:=TJSONObject(jsonObj.GetValue('charges'));
          oList:=tJSONArray(oData.GetValue('data'));
          for oItemList in oList do
          begin
              oCharge:=TJSONObject(oItemList);
              if oCharge.GetValue('receipt_url') <> nil then
                 FreceiptURL := oCharge.Get('receipt_url').JsonValue.value;
              if oCharge.GetValue('refunds') <> nil then
            if TJSONObject(oCharge.GetValue('refunds')).GetValue('url') <> nil then
              FrefundsURL := TJSONObject(oCharge.GetValue('refunds')).Get('url').JsonValue.value;
          end;
          if assigned(onStripePaymentStatus) then
            onStripePaymentStatus(sender, tsPaymentSuccess);
        end
        else
        begin
          FStatus_OUT := 'ERROR';
          FreturnMessage_OUT := 'Connection Error';
          Fstatus := 'Connection Error';
          if assigned(onStripePaymentStatus) then
            onStripePaymentStatus(sender, tsPaymentNoResponse);
        end
    end)
  except
    FStatus_OUT := 'ERROR';
    jsonObj := TJSONObject.ParseJSONValue(auxResponse) as TJSONObject;
    error := TJSONObject(jsonObj.Get('error').JsonValue);
    FreturnMessage_OUT := error.Get('message').JsonValue.value;
    Famount := 0;
    Fcurrency := '';
    Fcustomer := '';
    Fstatus := '';
    if assigned(onStripePaymentStatus) then
        onStripePaymentStatus(sender, tsPaymentStripeError);
  end;
end;

/// -----------------------------------------------------------------------------
/// <summary>
/// Procedure: TSubscription.GETSubscriptionDetails(sender : Tobject)
/// Author: CrisScanferla
/// Date: 12-May-2023
/// Result: Get Subscription Details as status, value, discounts and others
/// </summary>
/// ----------------------------------------------------------------------------
/// <remarks>
///     Ex call :  OBJSUBSCRIPTION.GETsubscriptionDetails(sender);
/// </remarks>
/// <param name="sender: Tobject">
/// </param>
/// <param name="OBJSUBSCRIPTION.onStripeSubscriptionStatus">
/// TsubscriptionStatus = (tssubscriptionSuccess,tssubscriptionMissingSk,tssubscriptionNoResponse, tssubscriptionStripeError);
/// TStripesubscriptionStatus = procedure (sender : Tobject; errortype : TsubscriptionStatus) of object;
/// Your app behaviour procedure(TStripesubscriptionStatus ) to deal with each one of the  TsubscriptionStatus .
/// </param>
/// <param name=OBJSUBSCRIPTION.sk >
/// string - sk from client at stripe API. There is two sk options for each client. Test SK and Live SK. Pass the correct one to access each ambient type
/// </param>
/// <param name=OBJSUBSCRIPTION.id_OUT >
/// string - id of the subscription at stripe APP. You can get this ID on InsertCheckout Response, when OBJCheckout.mode=Subscription
/// </param>
///------------------------------------------------------------------------------

procedure TSubscription.GETSubscriptionDetails(sender : Tobject);
var
  auxResponse: String;
  jsonObj,oCancellationDetails: TJSONObject;
  error: TJSONObject;

begin

  whGetSubscription := TWebHttpRequest.Create(self);

  whGetSubscription.Command := httpGET;
  whGetSubscription.Headers.Clear;
  whGetSubscription.Headers.Add('Cache-Control=no-cache, no-store, must-revalidate');
  whGetSubscription.Headers.Add('Content-Type=application/x-www-form-urlencoded');
  if trim(Fsk) = '' then
   begin
      onStripeSubscriptionStatus(sender,tsSubscriptionMissingSk);
      raise Exception.Create('');
    end
  else
  whGetSubscription.Headers.Add('Authorization=Bearer ' + Fsk);
  whGetSubscription.URL := 'https://api.stripe.com/v1/subscriptions/'+Fid_out;

  try
    whGetSubscription.execute(procedure(auxResponse:string; ARequest: TJSXMLHttpRequest)
    var amount_total:real;
    begin
        if auxResponse <> '' then
        begin
          jsonObj := TJSONObject.ParseJSONValue(auxResponse) as TJSONObject;
          FStatus_OUT := 'OK';
          FreturnMessage_OUT := auxResponse;
          if jsonObj.GetValue('currency') <> nil then
              Fcurrency:= jsonObj.Get('currency').JsonValue.value;
          if jsonObj.GetValue('customer') <> nil then
              Fcustomer:= jsonObj.Get('customer').JsonValue.value;
          if jsonObj.GetValue('status') <> nil then
              Fstatus := jsonObj.Get('status').JsonValue.value;
          oCancellationDetails := tJSONObject(jsonObj.GetValue('cancellation_details'));
          if not(oCancellationDetails=nil) then
          begin
              if oCancellationDetails.GetValue('reason') <> nil then
                  Fcancellation_reason := oCancellationDetails.Get('reason').JsonValue.value;
              if oCancellationDetails.GetValue('feedback') <> nil then
                  Fcancellation_feedback := oCancellationDetails.Get('feedback').JsonValue.value;
              if oCancellationDetails.GetValue('comment') <> nil then
                  Fcancellation_comment := oCancellationDetails.Get('comment').JsonValue.value;
          end;
          if jsonObj.GetValue('cancel_at') <> nil then
              if jsonObj.Get('cancel_at').JsonValue.value<>'null' then
                 Fcancel_at:= StrTodate(jsonObj.Get('cancel_at').JsonValue.value);
          if jsonObj.GetValue('canceled_at') <> nil then
              if jsonObj.Get('canceled_at').JsonValue.value<>'null' then
                   Fcancel_at:= StrTodate(jsonObj.Get('canceled_at').JsonValue.value);
          if assigned(onStripeSubscriptionStatus) then
            onStripeSubscriptionStatus(sender, tsSubscriptionSuccess);
        end
        else
        begin
          FStatus_OUT := 'ERROR';
          FreturnMessage_OUT := 'Connection Error';
          Fstatus := 'Connection Error';
          if assigned(onStripeSubscriptionStatus) then
            onStripeSubscriptionStatus(sender, tsSubscriptionNoResponse);
        end
    end)
  except
    FStatus_OUT := 'ERROR';
    jsonObj := TJSONObject.ParseJSONValue(auxResponse) as TJSONObject;
    error := TJSONObject(jsonObj.Get('error').JsonValue);
    FreturnMessage_OUT := error.Get('message').JsonValue.value;
    Famount := 0;
    Fcurrency := '';
    Fcustomer := '';
    Fstatus := '';
    if assigned(onStripeSubscriptionStatus) then
        onStripeSubscriptionStatus(sender, tsSubscriptionStripeError);
  end;
end;

/// -----------------------------------------------------------------------------
/// <summary>
/// Objects for Products
/// Author: CrisScanferla
/// Date: 06-Apr-2023
/// </summary>
/// ----------------------------------------------------------------------------
/// <remarks>
/// Object Description
///
/// TProductStatus = (tsProductSuccess,tsProductMissingSk,tsProductNoResponse, tsProducStripeError,tsProductMissingName,tsProductMissingID,
///                  tsProductUPDATESuccess,tsProductGETSuccess, tsProductDELETESuccess,tsProductListPricesSuccess);
/// TStripeProductStatus = procedure (sender : Tobject; errortype : TProductStatus) of object;
/// Tprices = array of TPrice;
///
///  CLASS TProduct
///
///properties:
/// sk : string - sk from client at stripe API. There is two sk options for each client. Test SK and Live SK. Pass the correct one to access each ambient type
/// id_OUT : string - id from product at stripe API
/// name : string. Name of the product
/// default_price : id of price at stripe API
/// description : string. Product description.
/// url : string. Product URL
/// Prices : array of tPrice.  One product can hava many prices.
/// ReturnMessage_OUT: string - complete return from Stripe
/// onStripeProductStatus: :TStripeProductStatus;; - use it for write your app behaviour with each one of the TStripeProductStatus  returned when using the class TProduct
/// </remarks>
///------------------------------------------------------------------------------

/// -----------------------------------------------------------------------------
/// <summary>
/// Procedure: TProduct.DELETEStripeProduct(sender: TObject)
/// Author: CrisScanferla
/// Date: 06-Apr-2023
/// Result: Deletes 1 product from Stripe
/// </summary>
/// ----------------------------------------------------------------------------
/// <remarks>
///     Ex call :  OBJPRODUCT.DELETEStripeProduct(sender);
/// </remarks>
/// <param name="sender: Tobject">
/// </param>
/// <param name="OBJPRODUCT.onStripeProductStatus">
/// Your app behaviour procedure(TStripeProductStatus) to deal with each one of the  TProductStatus.
/// </param>
/// <param name=OBJPRODUCT.sk >
/// string - sk from client at stripe API. There is two sk options for each client. Test SK and Live SK. Pass the correct one to access each ambient type
/// </param>
/// <param name=OBJPRODUCT.id_OUT >
/// string - id from product want to delete from stripe.
/// </param>
///------------------------------------------------------------------------------


procedure TProduct.DELETEStripeProduct(sender: TObject);
var
  auxResponse: String;
  jsonObj: TJSONObject;
  error: TJSONObject;
begin
  whProducts := TWebHttpRequest.Create(self);

  whProducts.Command := httpDELETE;
  whProducts.Headers.Clear;
  whProducts.Headers.Add('Cache-Control=no-cache, no-store, must-revalidate');
  whProducts.Headers.Add('Content-Type=application/x-www-form-urlencoded');
  if trim(Fsk) = '' then
   begin
      onStripeProductStatus( sender,tsProductMissingSk);
      raise Exception.Create('');
    end
  else
    whProducts.Headers.Add('Authorization=Bearer ' + Fsk);
  if Fid_OUT <> '' then
        whProducts.URL := 'https://api.stripe.com/v1/products/'+Fid_OUT
  else
  begin
     onStripeProductStatus( sender,tsProductMissingID);
     raise Exception.Create('');
  end;

  try
    //req := await(TJSXMLHttpRequest, whProducts.Perform());
    whProducts.execute(procedure(auxResponse:string; ARequest: TJSXMLHttpRequest)
    begin
        if auxResponse <> '' then
        begin
          jsonObj := TJSONObject.ParseJSONValue(auxResponse) as TJSONObject;
          Fid_OUT := jsonObj.Get('id').JsonValue.value;
          FStatus_OUT := 'OK';
          FreturnMessage_OUT := auxResponse;
          if assigned(onStripeProductStatus) then
            onStripeProductStatus(sender, tsProductDELETESuccess);
        end
        else
        begin
          FStatus_OUT := 'ERROR';
          FreturnMessage_OUT := 'Connection Error';
          Fid_OUT := '0';
          if assigned(onStripeProductStatus) then
            onStripeProductStatus(sender, tsProductNoResponse);
        end
    end)
  except
    FStatus_OUT := 'ERROR';
    jsonObj := TJSONObject.ParseJSONValue(auxResponse) as TJSONObject;
    error := TJSONObject(jsonObj.Get('error').JsonValue);
    FreturnMessage_OUT := error.Get('message').JsonValue.value;
    Fid_OUT := '0';
    if assigned(onStripeProductStatus) then
        onStripeProductStatus(sender, tsProducStripeError);
  end;
end;

/// -----------------------------------------------------------------------------
/// <summary>
/// Procedure: TProduct.GETStripeProduct(sender: TObject)
/// Author: CrisScanferla
/// Date: 06-Apr-2023
/// Result: Gets details from 1 product from Stripe API
/// </summary>
/// ----------------------------------------------------------------------------
/// <remarks>
///     Ex call :  OBJPRODUCT.GETStripeProduct(sender);
/// </remarks>
/// <param name="sender: Tobject">
/// </param>
/// <param name="OBJPRODUCT.onStripeProductStatus">
/// Your app behaviour procedure(TStripeProductStatus) to deal with each one of the  TProductStatus.
/// </param>
/// <param name=OBJPRODUCT.sk >
/// string - sk from client at stripe API. There is two sk options for each client. Test SK and Live SK. Pass the correct one to access each ambient type
/// </param>
/// <param name=OBJPRODUCT.id_OUT >
/// string - id from product want to get the details from stripe.
/// </param>
///------------------------------------------------------------------------------

procedure TProduct.GETStripeProduct(sender: TObject);
var
  auxResponse: String;
  jsonObj: TJSONObject;
  error: TJSONObject;

begin
  whProducts := TWebHttpRequest.Create(self);

  whProducts.Command := httpGET;
  whProducts.Headers.Clear;
  whProducts.Headers.Add('Cache-Control=no-cache, no-store, must-revalidate');
  whProducts.Headers.Add('Content-Type=application/x-www-form-urlencoded');
  if trim(Fsk) = '' then
   begin
      onStripeProductStatus( sender,tsProductMissingSk);
      raise Exception.Create('');
    end
  else
    whProducts.Headers.Add('Authorization=Bearer ' + Fsk);
  if Fid_OUT <> '' then
        whProducts.URL := 'https://api.stripe.com/v1/products/'+Fid_OUT
  else
  begin
     onStripeProductStatus( sender,tsProductMissingID);
     raise Exception.Create('');
  end;

  try
    //req := await(TJSXMLHttpRequest, whProducts.Perform());
    whProducts.execute(procedure(auxResponse:string; ARequest: TJSXMLHttpRequest)
    begin
        if auxResponse <> '' then
        begin
          jsonObj := TJSONObject.ParseJSONValue(auxResponse) as TJSONObject;
          Fid_OUT := jsonObj.Get('id').JsonValue.value;
          FStatus_OUT := 'OK';
          FreturnMessage_OUT := auxResponse;
          Fdefault_price := jsonObj.Get('default_price').JsonValue.value;
          Fname := jsonObj.Get('name').JsonValue.value;
          if tJSONString(jsonObj.GetValue('url')) <> nil then
             Furl := jsonObj.Get('url').JsonValue.value;
          if tJSONString(jsonObj.GetValue('description')) <> nil then
             Fdescription := jsonObj.Get('description').JsonValue.value;
          if assigned(onStripeProductStatus) then
            onStripeProductStatus(sender, tsProductGETSuccess);
        end
        else
        begin
          FStatus_OUT := 'ERROR';
          FreturnMessage_OUT := 'Connection Error';
          Fid_OUT := '0';
          if assigned(onStripeProductStatus) then
            onStripeProductStatus(sender, tsProductNoResponse);
        end
    end)
  except
    FStatus_OUT := 'ERROR';
    jsonObj := TJSONObject.ParseJSONValue(auxResponse) as TJSONObject;
    error := TJSONObject(jsonObj.Get('error').JsonValue);
    FreturnMessage_OUT := error.Get('message').JsonValue.value;
    Fid_OUT := '0';
    if assigned(onStripeProductStatus) then
        onStripeProductStatus(sender, tsProducStripeError);
  end;
end;

/// -----------------------------------------------------------------------------
/// <summary>
/// Procedure: TProduct.UPDATEStripeProduct(sender: TObject)
/// Author: CrisScanferla
/// Date: 06-Apr-2023
/// Result: updates details from 1 product at Stripe API
/// </summary>
/// ----------------------------------------------------------------------------
/// <remarks>
///     Ex call :  OBJPRODUCT.GETStripeProduct(sender);
/// </remarks>
/// <param name="sender: Tobject">
/// </param>
/// <param name="OBJPRODUCT.onStripeProductStatus">
/// Your app behaviour procedure(TStripeProductStatus) to deal with each one of the  TProductStatus.
/// </param>
/// <param name=OBJPRODUCT.sk >
/// string - sk from client at stripe API. There is two sk options for each client. Test SK and Live SK. Pass the correct one to access each ambient type
/// </param>
/// <param name=OBJPRODUCT.id_OUT >
/// string - id from product want to update at stripe API.
/// </param>
/// </param>
/// <param name=OBJPRODUCT.name >
/// string - name to be  updated at stripe API.
/// </param>
/// <param name=OBJPRODUCT.URL >
/// string - URL to be  updated at stripe API.
/// </param>
/// <param name=OBJPRODUCT.Description >
/// string - string to be  updated at stripe API.
/// </param>
/// <param name=OBJPRODUCT.Fdefault_price >
/// string - changes default price at stripe api.
/// </param>
///------------------------------------------------------------------------------


procedure TProduct.UPDATEStripeProduct(sender : Tobject);
var
  auxproduct,auxResponse: String;
  jsonObj: TJSONObject;
  error: TJSONObject;

begin
  auxproduct := '';
  if Trim(FName) <> '' then
     auxproduct := auxproduct + 'name=' + Fname + '&';
  if Trim(Furl) <> '' then
     auxproduct := auxproduct + 'url=' + Furl + '&';
  if Trim(Fdescription) <> '' then
     auxproduct := auxproduct + 'description=' + Fdescription + '&';
  if Trim(Fdefault_price) <> '' then
     auxproduct := auxproduct + 'default_price=' + Fdefault_price + '&';

  whProducts := TWebHttpRequest.Create(self);

  whProducts.Command := httpPOST;
  whProducts.Headers.Clear;
  whProducts.Headers.Add('Cache-Control=no-cache, no-store, must-revalidate');
  whProducts.Headers.Add('Content-Type=application/x-www-form-urlencoded');
  if trim(Fsk) = '' then
   begin
      onStripeProductStatus( sender,tsProductMissingSk);
      raise Exception.Create('');
    end
  else
    whProducts.Headers.Add('Authorization=Bearer ' + Fsk);
  if Fid_OUT <> '' then
        whProducts.URL := 'https://api.stripe.com/v1/products/'+Fid_OUT
  else
  begin
     onStripeProductStatus( sender,tsProductMissingID);
     raise Exception.Create('');
  end;
  whProducts.PostData := auxproduct;

  try
    //req := await(TJSXMLHttpRequest, whProducts.Perform());
    whProducts.execute(procedure(auxResponse:string; ARequest: TJSXMLHttpRequest)
    begin
          if auxResponse <> '' then
          begin
            jsonObj := TJSONObject.ParseJSONValue(auxResponse) as TJSONObject;
            Fid_OUT := jsonObj.Get('id').JsonValue.value;
            FStatus_OUT := 'OK';
            FreturnMessage_OUT := auxResponse;
            if assigned(onStripeProductStatus) then
              onStripeProductStatus(sender, tsProductUpdateSuccess);
          end
          else
          begin
            FStatus_OUT := 'ERROR';
            FreturnMessage_OUT := 'Connection Error';
            Fid_OUT := '0';
            if assigned(onStripeProductStatus) then
              onStripeProductStatus(sender, tsProductNoResponse);
          end
    end)
  except
    FStatus_OUT := 'ERROR';
    jsonObj := TJSONObject.ParseJSONValue(auxResponse) as TJSONObject;
    error := TJSONObject(jsonObj.Get('error').JsonValue);
    FreturnMessage_OUT := error.Get('message').JsonValue.value;
    Fid_OUT := '0';
    if assigned(onStripeProductStatus) then
        onStripeProductStatus(sender, tsProducStripeError);
  end;

end;

/// -----------------------------------------------------------------------------
/// <summary>
/// Procedure: TProduct.InsertStripeProduct(sender: TObject)
/// Author: CrisScanferla
/// Date: 06-Apr-2023
/// Result: Inserts a product at Stripe API
/// </summary>
/// ----------------------------------------------------------------------------
/// <remarks>
///     Ex call :  OBJPRODUCT.GETStripeProduct(sender);
/// </remarks>
/// <param name="sender: Tobject">
/// </param>
/// <param name="OBJPRODUCT.onStripeProductStatus">
/// Your app behaviour procedure(TStripeProductStatus) to deal with each one of the  TProductStatus.
/// </param>
/// <param name=OBJPRODUCT.sk >
/// string - sk from client at stripe API. There is two sk options for each client. Test SK and Live SK. Pass the correct one to access each ambient type
/// </param>
/// </param>
/// <param name=OBJPRODUCT.name >
/// string - name of the product.
/// </param>
/// <param name=OBJPRODUCT.URL >
/// string - URL of the product.
/// </param>
/// <param name=OBJPRODUCT.Description >
/// string - description of the product.
/// </param>
/// <param name=OBJPRODUCT.Fdefault_price >
/// string - default price of the product at stripe api.
/// </param>
///------------------------------------------------------------------------------

procedure TProduct.InsertStripeProduct(sender : Tobject);
var
  auxproduct,auxResponse: String;
  jsonObj: TJSONObject;
  error: TJSONObject;
begin

  auxproduct := '';
  if Trim(FName) <> '' then
     auxproduct := auxproduct + 'name=' + Fname + '&'
  else
  begin
     onStripeProductStatus( sender,tsProductMissingName);
     raise Exception.Create('');
  end;
  if Trim(Furl) <> '' then
     auxproduct := auxproduct + 'url=' + Furl + '&';
  if Trim(Fdescription) <> '' then
     auxproduct := auxproduct + 'description=' + Fdescription + '&';
  if Trim(Fdefault_price) <> '' then
     auxproduct := auxproduct + 'default_price=' + Fdefault_price + '&';

  whProducts := TWebHttpRequest.Create(self);

  whProducts.Command := httpPOST;
  whProducts.Headers.Clear;
  whProducts.Headers.Add('Cache-Control=no-cache, no-store, must-revalidate');
  whProducts.Headers.Add('Content-Type=application/x-www-form-urlencoded');
  if trim(Fsk) = '' then
   begin
      onStripeProductStatus( sender,tsProductMissingSk);
      raise Exception.Create('');
    end
  else
    whProducts.Headers.Add('Authorization=Bearer ' + Fsk);
  whProducts.URL := 'https://api.stripe.com/v1/products';
  whProducts.PostData := auxproduct;

  try
    whProducts.execute(procedure(auxResponse:string; ARequest: TJSXMLHttpRequest)
    begin
        if auxResponse <> '' then
        begin
          jsonObj := TJSONObject.ParseJSONValue(auxResponse) as TJSONObject;
          Fid_OUT := jsonObj.Get('id').JsonValue.value;
          FStatus_OUT := 'OK';
          FreturnMessage_OUT := auxResponse;
          if assigned(onStripeProductStatus) then
            onStripeProductStatus(sender, tsProductSuccess);
        end
        else
        begin
          FStatus_OUT := 'ERROR';
          FreturnMessage_OUT := 'Connection Error';
          Fid_OUT := '0';
          if assigned(onStripeProductStatus) then
            onStripeProductStatus(sender, tsProductNoResponse);
        end
    end)
  except
    FStatus_OUT := 'ERROR';
    jsonObj := TJSONObject.ParseJSONValue(auxResponse) as TJSONObject;
    error := TJSONObject(jsonObj.Get('error').JsonValue);
    FreturnMessage_OUT := error.Get('message').JsonValue.value;
    Fid_OUT := '0';
    if assigned(onStripeProductStatus) then
        onStripeProductStatus(sender, tsProducStripeError);
  end;
end;

/// -----------------------------------------------------------------------------
/// <summary>
/// Procedure: TProduct.PutPrice(value:TPrice);
/// Author: CrisScanferla
/// Date: 06-Apr-2023
/// Result: Inserts a price for a product at Stripe API
/// </summary>
/// ----------------------------------------------------------------------------
/// <remarks>
///     Ex call :  OBJPRODUCT.PutPrice(value:TPrice);
/// </remarks>
/// <param name=value:TPrice >
/// TPrice object to insert to a tProduct
/// </param>
///------------------------------------------------------------------------------

procedure TProduct.PutPrice(value:TPrice);
var i: integer;
begin
    i := length(FPrices);
    SetLength(FPrices,i+1);
    FPrices[i]:= TPrice.Create(self);
    FPrices[i].Fid_OUT:=value.Fid_OUT;
    FPrices[i].currency:=value.currency;
    FPrices[i].nickname:=value.nickname;
    FPrices[i].unit_amount:=value.unit_amount;
    FPrices[i].recurring:=value.recurring;
    if value.recurring then
    begin
       FPrices[i].recurringInterval:=value.recurringInterval;
       FPrices[i].recurringInterval_count:=value.recurringInterval_count;
    end;
end;

/// -----------------------------------------------------------------------------
/// <summary>
/// Procedure: TTaxRate.GetStripeTaxRate, TTaxRate.UpdateStripeTaxRate(sender : Tobject),   TTaxRate.UpdateStripeTaxRate(sender : Tobject); TTaxRate.InsertStripeTaxRate(sender : Tobject);
/// Author: CrisScanferla
/// Date: 10-May-2023
/// Result: Taxes at Stripe
/// </summary>
/// ----------------------------------------------------------------------------
/// <remarks>
///     We will not use the taxes, but the objects for automatic tax calculation and to configure taxes and insert
/// the taxes into each of the items of a checkout are already coded.
/// </remarks>
///------------------------------------------------------------------------------

procedure TTaxRate.GetStripeTaxRate(sender : Tobject);
var
  auxResponse: String;
  jsonObj: TJSONObject;
  error: TJSONObject;
begin
  whReqTax := TWebHttpRequest.Create(self);
  whReqTax.Command := httpGET;
  whReqTax.Headers.Clear;
  whReqTax.Headers.Add('Cache-Control=no-cache, no-store, must-revalidate');
  whReqTax.Headers.Add('Content-Type=application/x-www-form-urlencoded');
  if trim(Fsk) = '' then
   begin
      onStripeTaxStatus( sender,tsTaxMissingSk);
      raise Exception.Create('');
   end
  else
    whReqTax.Headers.Add('Authorization=Bearer ' + Fsk);
  if FID_OUT<>'' then
           whReqTax.URL := 'https://api.stripe.com/v1/tax_rates/'+FID_OUT
  else
  begin
    onStripeTaxStatus( sender,tsTaxMissingID);
    raise Exception.Create('');
  end;

  try
    whReqTax.execute(procedure(auxResponse:string; ARequest: TJSXMLHttpRequest)
    begin
        if auxResponse <> '' then
        begin
          jsonObj := TJSONObject.ParseJSONValue(auxResponse) as TJSONObject;
          Fid_OUT := jsonObj.Get('id').JsonValue.value;
          FStatus_OUT := 'OK';
          FreturnMessage_OUT := auxResponse;
          if jsonObj.Get('active').JsonValue.value='true' then
             Factive :=1
          else
             Factive :=0;
          Fdisplay_name := jsonObj.Get('display_name').JsonValue.value;
          Fcountry := jsonObj.Get('country').JsonValue.value;
          Fjurisdiction := jsonObj.Get('jurisdiction').JsonValue.value;
          Fpercentage := StrToInt(jsonObj.Get('percentage').JsonValue.value);
          Fstate := jsonObj.Get('state').JsonValue.value;
          Ftax_type := jsonObj.Get('tax_type').JsonValue.value;
          if jsonObj.Get('display_name').JsonValue.value='true' then
             Finclusive :=1
          else
             Finclusive :=0;

          if assigned(onStripeTaxStatus) then
            onStripeTaxStatus(sender, tsTaxGETSuccess);
        end
        else
        begin
          FStatus_OUT := 'ERROR';
          FreturnMessage_OUT := 'Connection Error';
          Fid_OUT := '0';
          if assigned(onStripeTaxStatus) then
            onStripeTaxStatus(sender, tsTaxNoResponse);
        end;
    end)
  except
    FStatus_OUT := 'ERROR';
    jsonObj := TJSONObject.ParseJSONValue(auxResponse) as TJSONObject;
    error := TJSONObject(jsonObj.Get('error').JsonValue);
    FreturnMessage_OUT := error.Get('message').JsonValue.value;
    Fid_OUT := '0';
    if assigned(onStripeTaxStatus) then
        onStripeTaxStatus(sender, tsTaxError);
  end;

end;

procedure TTaxRate.UpdateStripeTaxRate(sender : Tobject);
var
  auxtax,auxResponse: String;
  jsonObj: TJSONObject;
  error: TJSONObject;
begin
  auxtax := '';
  if inttostr(factive)<> '' then
     if Factive = 1 then
        auxtax := auxtax + 'active=true&'
     else
        auxtax := auxtax + 'active=false&';

  if Trim(Fcountry) <> '' then
     auxtax := auxtax + 'country=' + Fcountry + '&';
  if Fdisplay_name <> '' then
     auxtax := auxtax + 'display_name=' + Fdisplay_name + '&';
  if Fjurisdiction<>'' then
      auxtax := auxtax + 'jurisdiction='+Fjurisdiction+'&';
  if Fstate<>'' then
      auxtax := auxtax + 'state='+Fstate+'&';

  whReqTax := TWebHttpRequest.Create(self);
  whReqTax.Command := httpPOST;
  whReqTax.Headers.Clear;
  whReqTax.Headers.Add('Cache-Control=no-cache, no-store, must-revalidate');
  whReqTax.Headers.Add('Content-Type=application/x-www-form-urlencoded');
  if trim(Fsk) = '' then
   begin
      onStripeTaxStatus( sender,tsTaxMissingSk);
      raise Exception.Create('');
    end
  else
    whReqTax.Headers.Add('Authorization=Bearer ' + Fsk);
  if FID_OUT<>'' then
           whReqTax.URL := 'https://api.stripe.com/v1/tax_rates/'+FID_OUT;
  whReqTax.PostData := auxtax;

  try
    whReqTax.execute(procedure(auxResponse:string; ARequest: TJSXMLHttpRequest)
    begin
        if auxResponse <> '' then
        begin
          jsonObj := TJSONObject.ParseJSONValue(auxResponse) as TJSONObject;
          Fid_OUT := jsonObj.Get('id').JsonValue.value;
          FStatus_OUT := 'OK';
          FreturnMessage_OUT := auxResponse;
          if assigned(onStripeTaxStatus) then
            onStripeTaxStatus(sender, tsTaxUpdateSuccess);
        end
        else
        begin
          FStatus_OUT := 'ERROR';
          FreturnMessage_OUT := 'Connection Error';
          Fid_OUT := '0';
          if assigned(onStripeTaxStatus) then
            onStripeTaxStatus(sender, tsTaxNoResponse);
        end
    end)
  except
    FStatus_OUT := 'ERROR';
    jsonObj := TJSONObject.ParseJSONValue(auxResponse) as TJSONObject;
    error := TJSONObject(jsonObj.Get('error').JsonValue);
    FreturnMessage_OUT := error.Get('message').JsonValue.value;
    Fid_OUT := '0';
    if assigned(onStripeTaxStatus) then
        onStripeTaxStatus(sender, tsTaxError);
  end;

end;


procedure TTaxRate.InsertStripeTaxRate(sender : Tobject);
var
  auxtax,auxResponse: String;
  jsonObj: TJSONObject;
  error: TJSONObject;
begin
  auxtax := '';
  if Trim(Fcountry) <> '' then
     auxtax := auxtax + 'country=' + Fcountry + '&';
  if Fdisplay_name <> '' then
     auxtax := auxtax + 'display_name=' + Fdisplay_name + '&'
  else
  begin
     onStripeTaxStatus(sender,tsTaxMissingdisplay_name);
     raise Exception.Create('');
  end;
  if IntToStr(Finclusive) <> '' then
     if inclusive = 1 then
         auxtax := auxtax + 'inclusive=true&'
     else
         auxtax := auxtax + 'inclusive=false&'
  else
  begin
     onStripeTaxStatus( sender,tsTaxMissinginclusive);
     raise Exception.Create('');
  end;
  if Fjurisdiction<>'' then
      auxtax := auxtax + 'jurisdiction='+Fjurisdiction+'&';
  if IntToStr(FPercentage) <>'' then
      auxtax := auxtax + 'percentage='+IntToStr(FPercentage div 100)+'.'+IntToStr(FPercentage mod 100)+'&'
  else
  begin
     onStripeTaxStatus( sender,tsTaxMissingPercentage);
     raise Exception.Create('');
  end;
  if Fstate<>'' then
      auxtax := auxtax + 'state='+Fstate+'&';
  if FTax_type<>'' then
      auxtax := auxtax + 'tax_type='+FTax_type+'&';

  whReqTax := TWebHttpRequest.Create(self);
  whReqTax.Command := httpPOST;
  whReqTax.Headers.Clear;
  whReqTax.Headers.Add('Cache-Control=no-cache, no-store, must-revalidate');
  whReqTax.Headers.Add('Content-Type=application/x-www-form-urlencoded');
  if trim(Fsk) = '' then
   begin
      onStripeTaxStatus( sender,tsTaxMissingSk);
      raise Exception.Create('');
    end
  else
    whReqTax.Headers.Add('Authorization=Bearer ' + Fsk);
  whReqTax.URL := 'https://api.stripe.com/v1/tax_rates';
  whReqTax.PostData := auxtax;

  try
    whReqTax.execute(procedure(auxResponse:string; ARequest: TJSXMLHttpRequest)
    begin
        if auxResponse <> '' then
        begin
          jsonObj := TJSONObject.ParseJSONValue(auxResponse) as TJSONObject;
          Fid_OUT := jsonObj.Get('id').JsonValue.value;
          FStatus_OUT := 'OK';
          FreturnMessage_OUT := auxResponse;
          if assigned(onStripeTaxStatus) then
            onStripeTaxStatus(sender, tsTaxIncludeSucess);
        end
        else
        begin
          FStatus_OUT := 'ERROR';
          FreturnMessage_OUT := 'Connection Error';
          Fid_OUT := '0';
          if assigned(onStripeTaxStatus) then
            onStripeTaxStatus(sender, tsTaxNoResponse);
        end
    end)
  except
    FStatus_OUT := 'ERROR';
    jsonObj := TJSONObject.ParseJSONValue(auxResponse) as TJSONObject;
    error := TJSONObject(jsonObj.Get('error').JsonValue);
    FreturnMessage_OUT := error.Get('message').JsonValue.value;
    Fid_OUT := '0';
    if assigned(onStripeTaxStatus) then
        onStripeTaxStatus(sender, tsTaxError);
  end;

end;


end.
