unit Intf.Wrapper.Lookups;

interface

type
  // Define a generic class with a service name and a list of generic T types
  TLookupsWrapper<T> =  interface(IInvokable)

//    property Countries: TList<TCountryClass> read FCountries;
//    property States: TList<TStatesClass> read FStates;
  end;

implementation

end.
