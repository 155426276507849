unit Intf.PostalCodes;

interface

uses
  Intf.Ancestor;

type
  TPostalCodesClassWrapper = class
  private
    fPostalCode: string;
    fCityId: Integer;
    fPostalCodeId: Integer;
    fCountryCode: string;
  public
    property PostalCodeId: Integer read fPostalCodeId write fPostalCodeId;
    property CityId:       Integer read fCityId write fCityId;
    property PostalCode:   string read fPostalCode write fPostalCode;
    property CountryCode:  string read fCountryCode write fCountryCode;
  end;

  IPostalCodesClass = interface(IBaseClass)
  ['{95E2ADEF-356A-4E55-AE99-D0647529270D}']
     function ConvertToWrapper: TPostalCodesClassWrapper;
    // Getter and Setter methods for PostalCodeId
    function GetPostalCodeId: Integer;
    procedure SetPostalCodeId(const Value: Integer);

    // Getter and Setter methods for CityId
    function GetCityId: Integer;
    procedure SetCityId(const Value: Integer);

    // Getter and Setter methods for PostalCode
    function GetPostalCode: string;
    procedure SetPostalCode(const Value: string);

    // Getter and Setter methods for CountryCode
    function GetCountryCode: string;
    procedure SetCountryCode(const Value: string);

    // Properties for PostalCodeId, CityId, PostalCode, and CountryCode
    property PostalCodeId: Integer read GetPostalCodeId write SetPostalCodeId;
    property CityId: Integer read GetCityId write SetCityId;
    property PostalCode: string read GetPostalCode write SetPostalCode;
    property CountryCode: string read GetCountryCode write SetCountryCode;
  end;

implementation

end.
