unit uEmail;

interface

uses
  System.SysUtils,
  Web,
  WEBLib.REST;

function ValidateMobileNumber(MobileNumber: String): Boolean;

function ValidateEmail(Email: String): Boolean;
procedure SendEmail(Sender, ReplyTo, MailSubject, MailBody, recipients, cc: String; RetryCounter: Integer = 0);

implementation

function ValidateMobileNumber(MobileNumber: String): Boolean;
begin
  asm
    let re = /^[+]?[0-9()]+$/;
    Result = re.test(MobileNumber);
  end;
end;

function ValidateEmail(Email: String): Boolean;
begin
  asm
    let re = /\S+@\S+\.\S+/;
    Result = re.test(Email);
  end;
end;

procedure SendEmail(Sender, ReplyTo, MailSubject, MailBody, recipients, cc: String; RetryCounter: Integer);
var
  Email: THttpRequest;
begin
  Email := THttpRequest.Create(nil);
  Email.URL := 'https://sideminds.cymeg.com/empire/sideminds/SidemindsService/SendMail';
  Email.Command := httpPOST;

  // Set headers
  Email.Headers.Clear;
  Email.Headers.AddPair('Content-Type', 'application/json');
  Email.Headers.AddPair('tenant-url', 'https://sideminds.cymeg.com/');
  Email.Headers.AddPair('runningmode','rmDevelopment');

  // Construct the PostData as a JSON object
  Email.PostData := '{' +
    '"Sender": "' + Sender + '",' +
    '"replyto": "' + ReplyTo + '",' +
    '"MailSubject": "' + MailSubject + '",' +
    '"MailBody": "' + MailBody + '",' +
    '"recipients": "' + recipients + '",' +
    '"cc": "' + cc +'"'+
  '}';



  // Execute the request
  Email.Execute(
   procedure(AResponse: string; AReq: TJSXMLHttpRequest)
      begin
      Email.Free;
        console.log('Email Response Status:', AReq.Status);
        console.log('Email Body:', AResponse);
//        console.log('{' +
//            '"Sender": "' + Sender + '",' +
//            '"replyto": "' + ReplyTo + '",' +
//            '"MailSubject": "' + MailSubject + '",' +
//            '"MailBody": "' + MailBody + '",' +
//            '"recipients": "' + recipients + '",' +
//            '"cc": "' + cc +'"'+
//          '}');

          if AReq.Status = 200 then
          begin

            // Success, handle accordingly
            Window.location.reload(true);

          end
          else if RetryCounter < 2 then
          begin
            // Retry if not successful and retry count is less than 6
            SendEmail(Sender, ReplyTo, MailSubject, MailBody, recipients, cc, RetryCounter + 1);
          end
          else
          begin
            // Log error or handle failure
          end;

      end

  );    //end send email

end;



end.
