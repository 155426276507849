unit Intf.States;

interface

uses
  Intf.Ancestor;

type
  TStatesClassWrapper = Class
  private
    fCountryid: Integer;
    fStateName: string;
    fStateId:   Integer;
  public
    Property StateId:   Integer read fStateId write fStateId;
    Property StateName: string read fStateName write fStateName;
    Property CountryId: Integer read fCountryid write fCountryid;
  End;

  IStatesClass = interface(IBaseClass)
    ['{297BDDC4-6030-4F93-8A5E-D521F994C7B2}']
      function ConvertToWrapper: TStatesClassWrapper;
    // Getter and Setter methods for StateId
    function GetStateId: Integer;
    procedure SetStateId(const Value: Integer);

    // Getter and Setter methods for StateName
    function GetStateName: string;
    procedure SetStateName(const Value: string);

    // Getter and Setter methods for CountryId
    function GetCountryId: Integer;
    procedure SetCountryId(const Value: Integer);

    // Properties for StateId, StateName, and CountryId
    Property StateId: Integer read GetStateId write SetStateId;
    Property StateName: string read GetStateName write SetStateName;
    Property CountryId: Integer read GetCountryId write SetCountryId;
  end;

implementation

end.
