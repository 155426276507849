unit Intf.CompanyPositions;

interface

uses
  Intf.Ancestor;

type

  TCompanyPositionsClassWrapper = class
  private
    FTitle:       string;
    FDescription: string;
    FCompanyPositionId : integer;
  public

    property Title:       string read FTitle write fTitle;
    property Description: string read FDescription write FDescription;
    property CompanyPositionId: integer read FCompanyPositionId write FCompanyPositionId;
  end;

  ICompanyPositionsClass = interface(IBaseClass)
    ['{90E85222-9C5C-4913-8CA5-B7070E5F592D}']
    function ConvertToWrapper: TCompanyPositionsClassWrapper;
    function GetTitle: string;
    procedure SetTitle(const Value: string);

    function GetDescription: string;
    procedure SetDescription(const Value: string);

    function GetCompanyPositionId: integer;
    procedure SetCompanyPositionId(const Value: integer);

    property Title: string read GetTitle write SetTitle;
    property Description: string read GetDescription write SetDescription;
    property CompanyPositionId: integer read GetCompanyPositionId write SetCompanyPositionId;
  end;

implementation

end.
