unit Intf.Countries;

interface


uses
  // AppTypes,
  System.Generics.Collections,
  System.Classes;

type



  TCountryClassWrapper = class
  private
    FCountryID: Integer;
    FCountryName: String;
    FCountryCode: String;
    FContinent: String;

  public
    Property CountryId: Integer read FCountryID write FCountryID;
    property CountryName: String read FCountryName write FCountryName;
    Property CountryCode: String read FCountryCode write FCountryCode;
    Property Continent : String read FContinent write FContinent;
  end;



  ICountryClass = interface(IInvokable)
   ['{FD9CFA55-1FF1-49F8-B164-D105FD1165F7}']
    function ConvertToWrapper: TCountryClassWrapper;

    function GetCountryID: Integer;
    function GetCountryName: String;
    function GetCountryCode: String;
    function GetContinent: String;

    procedure SetCountryCode(const Value: String);
    procedure SetCountryID(const Value: Integer);
    procedure SetCountryName(const Value: String);
    procedure SetContinent(const Value: String);


    Property CountryId: Integer read GetCountryID write SetCountryID;
    property CountryName: String read GetCountryName write SetCountryName;
    Property CountryCode: String read GetCountryCode write SetCountryCode;
    Property Continent : String read GetContinent write SetContinent;

  end;

implementation

end.
