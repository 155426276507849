unit Data_UserProfile;

interface

uses JS, uSystemInfo;

var
  UserProfile_UserId: UInt64;
  UserProfile_Username: String;
  UserProfile_FirstName: String;
  UserProfile_LastName: String;
  UserProfile_Email: String;
  UserProfile_MobileNumber: String;
  UserProfile_Gender: String;
  UserProfile_CompanyName: String;
  UserProfile_Picture: String;
  UserProfile_JobTitle: String;
  UserProfile_Role: String;
  UserProfile_ProjectID: UInt64;
  UserProfile_ProductName: String;
  UserProfile_IsAdmin: Boolean;

  ProjectCompleted: Boolean;
  ProjectSelfCompleted: Boolean;

  ProjectsJSON: TJSArray;


  SystemInfo: TSystemInformation;


//  StakeholderFullName_ToDelete: String;
//  stakeholderID_ToDelete: UInt64;

implementation

end.
