unit Wrapper.Lookups;

interface

uses
  Generics.Collections,
  System.SysUtils,
  /// NO - use the interfaces ONLY
//  Obj.Countries,
//  Obj.States,
//  Obj.PostalCodes,
//  Obj.Cities,
//  Obj.CompanyPositions;

  intf.Countries,
  intf.States,
  intf.PostalCodes,
  intf.Cities,
  intf.CompanyPositions,
  intf.SeniorityRoles;

type
  TLookupsWrapper = class
  private
    FCountries:             TList<TCountryClassWrapper>;
    FStates:                TList<TStatesClasswrapper>;
    FCities:                TList<TCityClassWrapper>;
    FPostalCodes:           TList<TPostalCodesClassWrapper>;
    FCompanyPositionsCodes: TList<TCompanyPositionsClassWrapper>;
    FSeniorityRoles:        TList<TSeniorityRolesClassWrapper>;
  public
    constructor Create;
    destructor Destroy; override;

    property Countries: TList<TCountryClassWrapper> read FCountries write FCountries;
    property States: TList<TStatesClassWrapper> read FStates write FStates;
    property Cities: TList<tCityClassWrapper> read FCities write FCities;
    property CompanyPositionsCodes: TList<TCompanyPositionsClassWrapper> read FCompanyPositionsCodes write FCompanyPositionsCodes;
    property PostalCodes: TList<tPostalCodesClassWrapper> read FPostalCodes write FPostalCodes;
    property SeniorityRoles: TList<TSeniorityRolesClassWrapper> read FSeniorityRoles write FSeniorityRoles;
  end;

implementation

constructor TLookupsWrapper.Create;
begin
  FCountries             := TList<TCountryClassWrapper>.Create;
  FStates                := TList<TStatesClassWrapper>.Create;
  FCities                := TList<tCityClassWrapper>.Create;
  FPostalCodes           := TList<TPostalCodesClassWrapper>.Create;
  FCompanyPositionsCodes := TList<TCompanyPositionsClassWrapper>.Create;
  FSeniorityRoles        := TList<TSeniorityRolesClassWrapper>.Create;
end;

destructor TLookupsWrapper.Destroy;
begin
  FCountries.Free;
  FStates.Free;
  FCities.Free;
  FPostalCodes.Free;
  FCompanyPositionsCodes.Free;
  FSeniorityRoles.Free;
  inherited;
end;

end.
