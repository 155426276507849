unit Intf.SeniorityRoles;

interface

uses
  Intf.Ancestor;

type

  TSeniorityRolesClassWrapper = class
  private
    FSeniorityId : integer;
    FSeniority: string;
    FJobTitleId : integer;
    FJobTitle: string;
  public
    property SeniorityId: integer read FSeniorityId write FSeniorityId;
    property Seniority: string read FSeniority write FSeniority;
    property JobTitleId: integer read FJobTitleId write FJobTitleId;
    property JobTitle: string read FJobTitle write fJobTitle;
  end;

  ISeniorityRolesClass = interface(IBaseClass)
    ['{90E85222-9C5C-4913-8CA5-B7070E5F592D}']
    function ConvertToWrapper: TSeniorityRolesClassWrapper;
    function GetJobTitle: string;
    procedure SetJobTitle(const Value: string);

    function GetSeniority: string;
    procedure SetSeniority(const Value: string);

    function GetSeniorityId: integer;
    procedure SetSeniorityId(const Value: integer);

    function GetJobTitleId: integer;
    procedure SetJobTitleId(const Value: integer);

    property SeniorityId: integer read GetSeniorityId write SetSeniorityId;
    property Seniority: string read GetSeniority write SetSeniority;
    property JobTitleId: integer read GetJobTitleId write SetJobTitleId;
    property JobTitle: string read GetJobTitle write SetJobTitle;

  end;

implementation

end.
