{$REGION 'Documentation'}
/// <summary>
///   Custom enumerations commonly used throughout the application (both back
///   office and front end)
/// </summary>
/// <remarks>
///   All source code, materials and concepts are copyright to Appzoola and may
///   not be used or disseminated in any way outside of the Appzoola company.
/// </remarks>
{$ENDREGION}
unit Custom.Enumerations;
interface

type
   {$REGION 'Documentation'}
   /// <summary>
   ///   <list type="bullet">
   ///     <item>
   ///       tosOpen
   ///     </item>
   ///     <item>
   ///       tosClose
   ///     </item>
   ///   </list>
   /// </summary>
   {$ENDREGION}
   tOpenState          = (tosOpen, tosClose);
  {$REGION 'Documentation'}
  /// <summary>
  ///   Use to create specific code IF neccessary for the different
  ///   platforms.Additional platforms can be added as necessary
  ///   <list type="bullet">
  ///     <item>
  ///       Web
  ///     </item>
  ///     <item>
  ///       Mobile
  ///     </item>
  ///   </list>
  /// </summary>
  {$ENDREGION}
  TPlatform           = (Web, mobile);
  {$REGION 'Documentation'}
  /// <summary>
  ///   <list type="bullet">
  ///     <item>
  ///       crudList - Display a list of data
  ///     </item>
  ///     <item>
  ///       crudEdit - Display an edit form
  ///     </item>
  ///   </list>
  /// </summary>
  {$ENDREGION}
  TCrudType           = (crudList, CrudEdit);
  {$REGION 'Documentation'}
  /// <summary>
  ///   <list type="bullet">
  ///     <item>
  ///       ptLandLine
  ///     </item>
  ///     <item>
  ///       ptMobile
  ///     </item>
  ///     <item>
  ///       ptFax
  ///     </item>
  ///   </list>
  /// </summary>
  {$ENDREGION}
  TPhoneType          = (ptLandline, ptMobile, ptFax);
  {$REGION 'Documentation'}
  /// <summary>
  ///   <list type="bullet">
  ///     <item>
  ///       pdMonthly
  ///     </item>
  ///     <item>
  ///       pdAnnually
  ///     </item>
  ///   </list>
  ///   Additional options can be added as necessary
  /// </summary>
  {$ENDREGION}
  TPeriodicity        = (pdMonthly, pdAnnually);
  {$REGION 'Documentation'}
  /// <summary>
  ///   <list type="bullet">
  ///     <item>
  ///       gMale
  ///     </item>
  ///     <item>
  ///       gFemale
  ///     </item>
  ///     <item>
  ///       gWitheld : We will use this when someone does not wish to
  ///       disclose.
  ///     </item>
  ///   </list>
  /// </summary>
  {$ENDREGION}
  TGender             = (gmale, gfemale, gWithheld);
  {$REGION 'Documentation'}
  /// <summary>
  ///   <list type="bullet">
  ///     <item>
  ///       utEmpire - The highest level possible. This is INTERNAL only
  ///     </item>
  ///     <item>
  ///       utTenantOwner - A paid up subscriber with the top level Tenant
  ///       rights
  ///     </item>
  ///     <item>
  ///       utGeneric - Generic rights where permissions are not required
  ///     </item>
  ///     <item>
  ///       utSystemAdmin - A user defined by the tenant Admin to allocate
  ///       sub tasks to an Admin
  ///     </item>
  ///     <item>
  ///       utSystemStaff - Staff with lesser rights than admin
  ///     </item>
  ///     <item>
  ///       utSystemClient - rights allocated to clients only
  ///     </item>
  ///     <item>
  ///       utAll - everyone has permissions - Differes to utGeneric in
  ///       that permissions can still be added to this
  ///     </item>
  ///   </list>
  /// </summary>
  {$ENDREGION}
  TUserType           = (utEmpire ,utTenantOwner, utGeneric, utSystemAdmin, utSystemStaff,  utSystemClient , utAll );
  {$REGION 'Documentation'}
  /// <summary>
  ///   <list type="bullet">
  ///     <item>
  ///       dbEmpire - Our internal database, innaccessible to the outside
  ///       world
  ///     </item>
  ///     <item>
  ///       dbUniverse - The Universe contains all lookups and common
  ///       systems
  ///     </item>
  ///     <item>
  ///       dbFoundation - a common foundation for every solution. A copy
  ///       gets generated from a Template for each tenant
  ///     </item>
  ///     <item>
  ///       dbVault - A secure storage for all sensitive data. A copy is
  ///       generated from a Template for each tenant.
  ///     </item>
  ///     <item>
  ///       dbBnB - Specific tables for each particular BnB
  ///     </item>
  ///   </list>
  /// </summary>
  {$ENDREGION}
  TDatabaseSystem     = (dbEmpire, dbUniverse, dbFoundation, dbVault, dbBnB);
  {$REGION 'Documentation'}
  /// <summary>
  ///   Not used yet.
  ///   <list type="bullet">
  ///     <item>
  ///       ptReturnString
  ///     </item>
  ///     <item>
  ///       ptReturnBoolean
  ///     </item>
  ///     <item>
  ///       ptReturnServer
  ///     </item>
  ///   </list>
  /// </summary>
  {$ENDREGION}
  TProcType           = (ptReturnString, ptReturnBoolean, ptReturnServer);
  {$REGION 'Documentation'}
  /// <summary>
  ///   <list type="bullet">
  ///     <item>
  ///       logDatabase - Log issues to the database
  ///     </item>
  ///     <item>
  ///       logtextFile - Log to the Linux log system. This is also a
  ///       fallback if the database is unavailable
  ///     </item>
  ///     <item>
  ///       logBoth - will write to both
  ///     </item>
  ///   </list>
  /// </summary>
  {$ENDREGION}
  TLogLocation        = (logDatabase, logTextFile, logBoth);
  {$REGION 'Documentation'}
  /// <summary>
  ///   <list type="bullet">
  ///     <item>
  ///       menuWebsite - The main horizontal menu used for the front end
  ///       website
  ///     </item>
  ///     <item>
  ///       menuAdmin - Admin menu displayed to administrators. (Role
  ///       based)
  ///     </item>
  ///     <item>
  ///       menuUser - The more limited menu displayed to users based on
  ///       the BnB requirements
  ///     </item>
  ///     <item>
  ///       menuuserProfile - A drop down side menu with the profile
  ///       information of the user
  ///     </item>
  ///   </list>
  /// </summary>
  {$ENDREGION}
  TSystemMenuType     = (menuWebsite , menuAdmin, menuUser, menuUserProfile);
  {$REGION 'Documentation'}
  /// <summary>
  ///   <list type="bullet">
  ///     <item>
  ///       tsHomePage: The landing page of the front end website
  ///     </item>
  ///     <item>
  ///       tsAdmin: The core admin dashboard shell displayed on admin
  ///       login (Note: this will be the administrator of the website)
  ///     </item>
  ///     <item>
  ///       tsUserDashboard: The dashboard displayed when a client logs in.
  ///       it has the same look and feel as the admin dashboard but has
  ///       limited functions
  ///     </item>
  ///   </list>
  /// </summary>
  {$ENDREGION}
  TActiveSession      = (tsHomePage, tsAdmin, tsUserDashboard);
  {$REGION 'Documentation'}
  /// <summary>
  ///   Determine if the front end should be run in production or development
  ///   mode
  ///   <list type="bullet">
  ///     <item>
  ///       rmProduction
  ///     </item>
  ///     <item>
  ///       rmDevelopment
  ///     </item>
  ///   </list>
  /// </summary>
  {$ENDREGION}
  TRunningMode = (rmProduction, rmDevelopment);
  TSurveyRole  = (srNone, srParticipant, srProjectManager, srCoach, srRater) ;

  TAssessments  = (aNone, aMiniAssessment, aFullAssessDigital ,aFullAssessVidDebrief );


implementation
end.
