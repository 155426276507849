unit Intf.Cities;

interface
uses
  intf.Ancestor;

type
  TCityClassWrapper = Class
    private
      FCityId: Integer;
      FCountryCode: string;
      FCityName: string;
    Public
      property CityId: Integer read FCityId write FCityId;
      property CityName: string read FCityName write FCityName;
      property CountryCode: string read FCountryCode write fCountryCode;
  End;

  ICityClass = interface(IBaseClass)
  ['{1ED94A79-05AE-4BE5-8D63-D70BDB5F8C30}']
    function ConvertToWrapper: TCityClassWrapper;
    // Getter and Setter methods for CityId
    function GetCityId: Integer;
    procedure SetCityId(const Value: Integer);

    // Getter and Setter methods for CityName
    function GetCityName: string;
    procedure SetCityName(const Value: string);

    // Getter and Setter methods for CountryCode
    function GetCountryCode: string;
    procedure SetCountryCode(const Value: string);

    // Properties for CityId, CityName, and CountryCode
    property CityId: Integer read GetCityId write SetCityId;
    property CityName: string read GetCityName write SetCityName;
    property CountryCode: string read GetCountryCode write SetCountryCode;
  end;

implementation

end.

