unit Wrapper.Person;

interface

uses
  system.Classes,
  System.SysUtils,
  system.Generics.Collections,
  Custom.Enumerations,
  WEBLib.JSON;

type

  TPersonContainer = class(TObject)
  private
   //
  public

    Email: String;
    Firstname: String;
    Surname: String;
    Picture: String;
    Password: String;
    MustEncrypt: boolean;
    SXid: String;
    CompanyID:   integer;
    Twofactorenabled: boolean;
    CompanyName: string;
    CompanyNumber: string;
    VatNo: string;
    ContactDetail: string;
    AddressLine1: string;
    AddressLine2: string;
    CityID: Integer;
    StateID: Integer;
    CountryID: Integer;
    CompanyPositionId : Integer;
    SurveyRole : Integer;
  end;

implementation


end.
